import Layout from "../Layout/MainLayout";
import {
  Typography,
  Row,
  Col,
  Select,
  Switch,
  Form,
  Input,
  Collapse,
  Button,
  Tabs,
  TimePicker,
  Checkbox,
} from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import CUSTOMHM from "../Components/charts/ChargingHeatmap";
import ChargingStationCard from "../Components/cards/ChargingStationCard";
import ColumnLineDualAxis from "../Components/charts/ColumnLineDualAxis";
import DualLine from "../Components/charts/DualLine";
import GaugeProgress from "../Components/charts/GaugeProgress";
import GaugeProgressSingle from "../Components/charts/GaugeProgressSingle";
import { useEffect, useState, useRef } from "react";
import moment from "moment";
import { FaAngleLeft, FaAngleRight, FaPlus } from "react-icons/fa";

const LoadPeak = () => {
  const { Panel } = Collapse;

  const onFinish = (values) => {
    // console.log("Received values of form:", values);
  };
  const [height, setHeight] = useState(120);
  const [Heatmapheight, setHeatmapHeight] = useState(120);
  const [activeNode, setActiveNode] = useState("all");
  const [stationActiveNode, setstationActiveNode] = useState("station1");

  const [mainData, setMainData] = useState([
    {
      key: "all",
      values: [],
    },
    {
      key: "monday",
      values: [],
    },
    {
      key: "tuesday",
      values: [],
    },
    {
      key: "wednesday",
      values: [],
    },
    {
      key: "thursday",
      values: [],
    },
    {
      key: "friday",
      values: [],
    },
    {
      key: "saturday",
      values: [],
    },
    {
      key: "sunday",
      values: [],
    },
  ]);

  const [startDate, setStartDate] = useState(
    moment().subtract(1, "month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const heightRef = useRef(null);
  const heatMapRef = useRef(null);

  let Data = [
    {
      title: "All",
      key: "all",
    },
    {
      title: "Monday",
      key: "monday",
    },
    {
      title: "Tuesday",
      key: "tuesday",
    },
    {
      title: "Wednesday",
      key: "wednesday",
    },
    {
      title: "Thursday",
      key: "thursday",
    },
    {
      title: "Friday",
      key: "friday",
    },
    {
      title: "Saturday",
      key: "saturday",
    },
    {
      title: "Sunday",
      key: "sunday",
    },
  ];

  let chargingData = [
    {
      title: "Station 1",
      key: "station1",
    },
    {
      title: "Station 2",
      key: "station2",
    },
    {
      title: "Station 3",
      key: "station3",
    },
    {
      title: "Station 4",
      key: "station4",
    },
  ];

  useEffect(() => {
    setHeight(heightRef?.current?.offsetHeight);
  }, [heightRef?.current?.offsetHeight]);

  useEffect(() => {
    setHeatmapHeight(heatMapRef?.current?.offsetHeight);
  }, [heatMapRef?.current?.offsetHeight]);

  return (
    <Layout active="emobility">
      <div className="live-main energy-main">
        {/* <Typography.Title level={2}>E-Mobility</Typography.Title> */}

        <Row gutter={[30, 30]}>
          <Col xs={24} md={24} lg={8}>
            <ChargingStationCard setHeight={setHeight} />
          </Col>

          <Col xs={24} md={12} lg={8}>
            <div
              className="live-card white-card"
              style={{
                height: "100%",
              }}
            >
              <Typography.Title
                className="title main-title"
                level={4}
                style={{ fontWeight: "500", marginBottom: "20px" }}
              >
                Usage & Cost
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      width: 8,
                      height: 8,
                      background: "#F99600",
                      borderRadius: "50%",
                      marginRight: 5,
                    }}
                  />
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: "0.75rem",
                      color: "black",
                      margin: 0,
                    }}
                  >
                    76,5 €
                  </p>

                  <div
                    style={{
                      width: 8,
                      height: 8,
                      background: "#099CFF",
                      borderRadius: "50%",
                      margin: "0 5px 0 10px",
                    }}
                  />
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: "0.75rem",
                      margin: 0,
                      color: "black",
                    }}
                  >
                    247 kWh
                  </p>
                </div>
                <Select defaultValue={"day"} style={{ width: "max-content" }}>
                  <Select.Option value="day">1 day</Select.Option>
                  <Select.Option value="week">1 week</Select.Option>
                  <Select.Option value="month">1 month</Select.Option>
                  <Select.Option value="year">1 year</Select.Option>
                </Select>
              </Typography.Title>

              <div
                className="chart-wrapper"
                style={{
                  width: "100%",
                  height: 100,
                }}
              >
                <DualLine />
              </div>
              {/* <div className="flex-around">
                <div>
                  <Typography.Title
                    level={2}
                    className="value"
                    style={{
                      //   color: "var(--green)",
                      fontWeight: "600",
                      margin: "0",
                    }}
                  >
                    1.272 €
                  </Typography.Title>
                  <div className="percent">
                    <span
                      style={{
                        fontSize: "0.9rem",
                        color: "var(--red)",
                        fontWeight: "bold",
                      }}
                    >
                      2.5% <BsArrowUp style={{ strokeWidth: 2 }} />
                    </span>
                    &nbsp;
                    <span className="secondary">than last day</span>
                  </div>
                </div>
                <div>
                  <Typography.Title
                    level={2}
                    className="value"
                    style={{
                      //   color: "var(--green)",
                      fontWeight: "600",
                      margin: "0",
                    }}
                  >
                    452 kWh
                  </Typography.Title>
                  <div className="percent">
                    <span
                      style={{
                        fontSize: "0.9rem",
                        color: "var(--green)",
                        fontWeight: "bold",
                      }}
                    >
                      1.3% <BsArrowDown style={{ strokeWidth: 2 }} />
                    </span>
                    &nbsp;
                    <span className="secondary">than last day</span>
                  </div>
                </div>
              </div> */}
            </div>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <div
              className="live-card white-card"
              style={{
                height: "100%",
              }}
            >
              <Typography.Title
                className="title main-title"
                level={4}
                style={{ fontWeight: "500" }}
              >
                Carbon Footprint Reduction
                <Select defaultValue={"day"} style={{ width: "max-content" }}>
                  <Select.Option value="day">1 day</Select.Option>
                  <Select.Option value="week">1 week</Select.Option>
                  <Select.Option value="month">1 month</Select.Option>
                  <Select.Option value="year">1 year</Select.Option>
                </Select>
              </Typography.Title>

              <div className="head-bottom">
                <div
                  style={{ position: "relative", height: 150, width: "50%" }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "-35%",
                      left: "50%",
                      transform: "translate(-50%, 0)",
                    }}
                  >
                    <GaugeProgress />
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "-35%",
                      left: "50%",
                      transform: "translate(-50%, 0)",
                    }}
                  >
                    <GaugeProgressSingle />
                  </div>
                </div>

                <div className="head-right">
                  {/* <div className="co2">
                    <div
                      style={{
                        background: "#EE3D29",
                        width: 12,
                        height: 12,
                        borderRadius: "50%",
                        marginRight: 5,
                        marginTop: 2,
                      }}
                    />
                    <p>
                      CO2 Saved
                      <br />
                      <span>34 Kg</span>
                    </p>
                  </div> */}

                  <div
                    className="fuel"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          background: "#07A0E0",
                          width: 15,
                          height: 15,
                          borderRadius: "50%",
                        }}
                      />
                      &nbsp;
                      <p
                        style={{
                          fontSize: "1.3rem",
                          fontWeight: "500",
                          margin: 0,
                        }}
                      >
                        Fuel Replaced
                      </p>
                    </div>

                    <p
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "600",
                        margin: 0,
                        marginLeft: "1rem",
                      }}
                    >
                      257 L
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="flex-around">
                <div>
                  <Typography.Title
                    level={2}
                    className="value"
                    style={{
                      color: "var(--primary)",
                      fontWeight: "600",
                      margin: "0",
                    }}
                  >
                    34 kg
                  </Typography.Title>

                  <Typography.Title level={4} style={{ margin: 0 }}>
                    CO2 Saved
                  </Typography.Title>

                  <div className="percent" style={{ margin: 0 }}>
                    <span
                      style={{
                        fontSize: "0.8rem",
                        color: "var(--red)",
                        fontWeight: "bold",
                      }}
                    >
                      2.5% <BsArrowUp style={{ strokeWidth: 2 }} />
                    </span>
                    &nbsp;
                    <span className="secondary">than last day</span>
                  </div>
                </div>
                <div>
                  <Typography.Title
                    level={2}
                    className="value"
                    style={{
                      color: "var(--primary)",
                      fontWeight: "600",
                      margin: "0",
                    }}
                  >
                    257 L
                  </Typography.Title>
                  <Typography.Title level={4} style={{ margin: 0 }}>
                    Fuel Replaced
                  </Typography.Title>
                  <div className="percent" style={{ margin: 0 }}>
                    <span
                      style={{
                        fontSize: "0.8rem",
                        color: "var(--green)",
                        fontWeight: "bold",
                      }}
                    >
                      1.3% <BsArrowDown style={{ strokeWidth: 2 }} />
                    </span>
                    &nbsp;
                    <span className="secondary">than last day</span>
                  </div>
                </div>
              </div> */}
          </Col>

          <Col xs={24}>
            <div className="white-card">
              <Typography.Title
                className="title main-title"
                level={4}
                style={{ fontWeight: "600", marginBottom: "30px" }}
              >
                Consumption
                <Select defaultValue={"week"} style={{ width: "max-content" }}>
                  <Select.Option value="week">1 week</Select.Option>
                  <Select.Option value="month">1 month</Select.Option>
                  <Select.Option value="year">1 year</Select.Option>
                </Select>
              </Typography.Title>

              <div
                style={{
                  width: "100%",
                }}
              >
                <ColumnLineDualAxis
                  leftUnit="kWh"
                  rightUnit="kWh"
                  //   xUnit="Days"
                  alias={["Energy Production", "Total"]}
                  dot={false}
                  isStack
                  radius={[4, 4, 0, 0]}
                />
              </div>
            </div>
          </Col>

          <Col xs={24} lg={12} xl={12}>
            <div className="live-card white-card" ref={heatMapRef}>
              <Typography.Title
                className="title main-title"
                level={4}
                style={{ fontWeight: "500" }}
              >
                Smart Charging and Charging Profiles
              </Typography.Title>
              {/* <Typography.Text
                type="secondary"
                style={{
                  fontWeight: "600",
                  marginBottom: "30px",
                  letterSpacing: "0.5px",
                }}
              >
                Define the maximum current per day and time in the schedule
                below
              </Typography.Text> */}

              <div>
                <Form layout="vertical">
                  <Row gutter={[10, 5]}>
                    <Col xs={24} md={12}>
                      <Form.Item name="name" label="Charging profile name">
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item name={"current"} label="Maximum Current (A)">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item name={"renewable"}>
                        <div className="flex-between">
                          <Checkbox
                            style={{
                              color: "#2EC276",
                            }}
                          >
                            Use only Renewable Energy
                          </Checkbox>
                          <Button type="primary" htmlType="submit">
                            Save
                          </Button>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>

              <Tabs defaultActiveKey="1" animated>
                <Tabs.TabPane tab="Days" key="1">
                  {/* <Collapse accordion ghost bordered={false}> */}
                  <Row gutter={[10, 20]}>
                    <Col lg={4} className="Days-names-wrapper">
                      {Data.map((data, index) => (
                        <div
                          key={index}
                          className="flex-between"
                          style={
                            activeNode === data.key ? { color: "#0098FD" } : {}
                          }
                          onClick={() => {
                            setActiveNode(data.key);
                          }}
                        >
                          <span
                            style={{ fontWeight: "bold", width: "max-content" }}
                          >
                            {data.title}&nbsp;
                          </span>
                          {activeNode === data.key ? (
                            <FaAngleLeft />
                          ) : (
                            <FaAngleRight />
                          )}
                        </div>
                      ))}
                    </Col>
                    <Col lg={14}>
                      <div className="white-card">
                        <Form
                          layout="vertical"
                          onFinish={onFinish}
                          // onValuesChange={(values, allValues) => {
                          //   console.log("change all values", allValues);

                          //   let temp =
                          //     allValues[activeNode] &&
                          //     allValues[activeNode].length > 0
                          //       ? [
                          //           // let temp  = mainData[activeNode].values = [
                          //           {
                          //             from: allValues.from._d,
                          //             to: allValues.to._d,
                          //             power: allValues.power,
                          //             unit: allValues.unit,
                          //           },
                          //           ...allValues[activeNode],
                          //         ]
                          //       : [
                          //           // let temp  = mainData[activeNode].values = [
                          //           {
                          //             from: allValues.from._d,
                          //             to: allValues.to._d,
                          //             power: allValues.power,
                          //             unit: allValues.unit,
                          //           },
                          //         ];
                          //   // setMainData([...mainData]);
                          //   console.log("temp", temp);
                          // }}
                          autoComplete="off"
                        >
                          <Row gutter={[20, 20]} align="middle">
                            <Col xs={24} md={12} xl={6}>
                              <Form.Item name="from" label="From">
                                <TimePicker
                                // defaultValue={moment()}
                                // format={"HH:MM:SS"}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                              <Form.Item name="to" label="To">
                                <TimePicker />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                              <Form.Item name="power" label="Power">
                                <Input type="number" style={{ height: 32 }} />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                              <Form.Item name="unit" label="Power Unit">
                                <Switch
                                  checkedChildren="kW"
                                  unCheckedChildren="%"
                                  defaultChecked={false}
                                />
                              </Form.Item>
                            </Col>

                            <Form.List name={activeNode}>
                              {/*  */}
                              {(fields, { add, remove }) => (
                                <>
                                  {fields.map(
                                    ({ key, name, ...restField }, index) => (
                                      <>
                                        <Col xs={24} md={12} lg={6} key={index}>
                                          <Form.Item
                                            {...restField}
                                            name={[name, "from"]}
                                            label="From"
                                          >
                                            <TimePicker />
                                          </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12} lg={6}>
                                          <Form.Item
                                            {...restField}
                                            name={[name, "to"]}
                                            label="To"
                                          >
                                            <TimePicker />
                                          </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12} lg={6}>
                                          <Form.Item
                                            {...restField}
                                            name={[name, "power"]}
                                            label="Power"
                                          >
                                            <Input
                                              type="number"
                                              style={{ height: 32 }}
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12} lg={3}>
                                          <Form.Item
                                            {...restField}
                                            name={[name, "unit"]}
                                            label="Power Unit"
                                          >
                                            <Switch
                                              checkedChildren="kW"
                                              unCheckedChildren="%"
                                              defaultChecked={false}
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12} lg={3}>
                                          <MinusCircleOutlined
                                            size={20}
                                            onClick={() => remove(name)}
                                          />
                                        </Col>
                                      </>
                                    )
                                  )}
                                  <Col xs={24}>
                                    <Form.Item>
                                      <Button
                                        shape="circle"
                                        style={{
                                          backgroundColor: "#0098FD",
                                          display: "flex",
                                          // width: "2rem",
                                          // height: "2rem",
                                          border: "50%",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                        icon={<FaPlus color="#fff" />}
                                        onClick={() => add()}
                                      ></Button>
                                    </Form.Item>
                                  </Col>
                                </>
                              )}
                            </Form.List>
                            <Col xs={24}>
                              <Form.Item>
                                <Button block type="primary" htmlType="submit">
                                  Submit
                                </Button>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h3 style={{ opacity: "50%", fontSize: "0.8rem" }}>
                          12am
                        </h3>
                        <h3 style={{ opacity: "50%", fontSize: "0.8rem" }}>
                          12pm
                        </h3>
                        <h3 style={{ opacity: "50%", fontSize: "0.8rem" }}>
                          12am
                        </h3>
                      </div>
                      <div className="smart-charging-progressbar-wrapper">
                        {[1, 2, 3, 2, 5, 4, 1, 3].map((per, index) => (
                          <div
                            key={index}
                            style={{
                              width: "100%",
                              height: "1rem",
                              backgroundColor: "#0098FD",
                              borderRadius: "1rem",
                              position: "relative",
                            }}
                          >
                            {" "}
                            <div
                              style={{
                                width: `calc(100%/3)`,
                                height: "1rem",
                                backgroundColor: "#0063A5",
                                top: "0",
                                left: `${per}0%`,
                                borderRadius: "1rem",
                                position: "absolute",
                              }}
                            ></div>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>

                  {/* </Collapse> */}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Charging station" key="2">
                  {/* <Collapse accordion ghost bordered={false}> */}
                  <Row gutter={[10, 20]}>
                    <Col lg={4} className="Days-names-wrapper">
                      {chargingData.map((data, index) => (
                        <div
                          key={index}
                          className="flex-between"
                          style={
                            stationActiveNode === data.key
                              ? { color: "#0098FD" }
                              : {}
                          }
                          onClick={() => {
                            setstationActiveNode(data.key);
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "bold",
                              width: "max-content",
                            }}
                          >
                            {data.title}&nbsp;
                          </span>
                          {stationActiveNode === data.key ? (
                            <FaAngleLeft />
                          ) : (
                            <FaAngleRight />
                          )}
                        </div>
                      ))}
                    </Col>
                    <Col lg={14}>
                      <div className="white-card">
                        <Form
                          layout="vertical"
                          onFinish={onFinish}
                          // onValuesChange={(values, allValues) => {
                          //   console.log("change all values", allValues);

                          //   let temp =
                          //     allValues[activeNode] &&
                          //     allValues[activeNode].length > 0
                          //       ? [
                          //           // let temp  = mainData[activeNode].values = [
                          //           {
                          //             from: allValues.from._d,
                          //             to: allValues.to._d,
                          //             power: allValues.power,
                          //             unit: allValues.unit,
                          //           },
                          //           ...allValues[activeNode],
                          //         ]
                          //       : [
                          //           // let temp  = mainData[activeNode].values = [
                          //           {
                          //             from: allValues.from._d,
                          //             to: allValues.to._d,
                          //             power: allValues.power,
                          //             unit: allValues.unit,
                          //           },
                          //         ];
                          //   // setMainData([...mainData]);
                          //   console.log("temp", temp);
                          // }}
                          autoComplete="off"
                        >
                          <Row gutter={[20, 20]} align="middle">
                            <Col xs={24} md={12} xl={6}>
                              <Form.Item name="from" label="From">
                                <TimePicker
                                // defaultValue={moment()}
                                // format={"HH:MM:SS"}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                              <Form.Item name="to" label="To">
                                <TimePicker />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                              <Form.Item name="power" label="Power">
                                <Input type="number" style={{ height: 32 }} />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                              <Form.Item name="unit" label="Power Unit">
                                <Switch
                                  checkedChildren="kW"
                                  unCheckedChildren="%"
                                  defaultChecked={false}
                                />
                              </Form.Item>
                            </Col>

                            <Form.List name={activeNode}>
                              {/*  */}
                              {(fields, { add, remove }) => (
                                <>
                                  {fields.map(
                                    ({ key, name, ...restField }, index) => (
                                      <>
                                        <Col xs={24} md={12} lg={6} key={index}>
                                          <Form.Item
                                            {...restField}
                                            name={[name, "from"]}
                                            label="From"
                                          >
                                            <TimePicker />
                                          </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12} lg={6}>
                                          <Form.Item
                                            {...restField}
                                            name={[name, "to"]}
                                            label="To"
                                          >
                                            <TimePicker />
                                          </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12} lg={6}>
                                          <Form.Item
                                            {...restField}
                                            name={[name, "power"]}
                                            label="Power"
                                          >
                                            <Input
                                              type="number"
                                              style={{ height: 32 }}
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12} lg={3}>
                                          <Form.Item
                                            {...restField}
                                            name={[name, "unit"]}
                                            label="Power Unit"
                                          >
                                            <Switch
                                              checkedChildren="kW"
                                              unCheckedChildren="%"
                                              defaultChecked={false}
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12} lg={3}>
                                          <MinusCircleOutlined
                                            size={20}
                                            onClick={() => remove(name)}
                                          />
                                        </Col>
                                      </>
                                    )
                                  )}
                                  <Col xs={24}>
                                    <Form.Item>
                                      <Button
                                        shape="circle"
                                        style={{
                                          backgroundColor: "#0098FD",
                                          display: "flex",
                                          // width: "2rem",
                                          // height: "2rem",
                                          border: "50%",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                        icon={<FaPlus color="#fff" />}
                                        onClick={() => add()}
                                      ></Button>
                                    </Form.Item>
                                  </Col>
                                </>
                              )}
                            </Form.List>
                            <Col xs={24}>
                              <Form.Item>
                                <Button block type="primary" htmlType="submit">
                                  Submit
                                </Button>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h3 style={{ opacity: "50%", fontSize: "0.8rem" }}>
                          12am
                        </h3>
                        <h3 style={{ opacity: "50%", fontSize: "0.8rem" }}>
                          12pm
                        </h3>
                        <h3 style={{ opacity: "50%", fontSize: "0.8rem" }}>
                          12am
                        </h3>
                      </div>
                      <div className="smart-charging-progressbar-wrapper">
                        {[1, 2, 3, 4, 5, 6, 7, 8].map((per, index) => (
                          <div
                            key={index}
                            style={{
                              width: "100%",
                              height: "1rem",
                              backgroundColor: "#0098FD",
                              borderRadius: "1rem",
                              position: "relative",
                            }}
                          >
                            {" "}
                            <div
                              style={{
                                width: `${per}0%`,
                                height: "1rem",
                                backgroundColor: "#0063A5",
                                top: "0",
                                left: `0%`,
                                borderRadius: "1rem",
                                position: "absolute",
                              }}
                            ></div>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                  {/* </Collapse> */}
                </Tabs.TabPane>
              </Tabs>
            </div>
          </Col>

          <Col xs={24} lg={12} xl={12}>
            <div
              className="live-card heatmap-card"
              style={{
                height: Heatmapheight,
              }}
            >
              <Typography.Title
                className="title main-title"
                level={4}
                style={{ fontWeight: "500", marginBottom: "30px" }}
              >
                Popular Charging Time
              </Typography.Title>
              <CUSTOMHM />
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default LoadPeak;
