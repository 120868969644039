import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";
import Profile from "./Pages/Profile";
import Digitalization from "./Pages/Digitalization";
import Analysis from "./Pages/Analysis";
import LoadPeakNew from "./Pages/LoadPeakNew";
import EnergyProductionNew from "./Pages/EnergyProductionNew";
import Live from "./Pages/Live";
import EmobilityNew from "./Pages/EmobilityNew";
import Compare from "./Pages/Compare";
import Co2Emission from "./Pages/CO2Emission";

import PrivateRoutes from "./PrivateRoutes";
const Routes = () => {
  return (
    <Router>
      <Switch>
        <PrivateRoutes path="/" exact component={Home} />
        <Route path="/login" exact component={Login} />
        <Route path="/signup" exact component={Signup} />
        <PrivateRoutes path="/settings" exact component={Profile} />
        <PrivateRoutes
          path="/digitalization"
          exact
          component={Digitalization}
        />
        <PrivateRoutes path="/analysis" exact component={Analysis} />
        <PrivateRoutes path="/load-peak" exact component={LoadPeakNew} />
        <PrivateRoutes path="/live" exact component={Live} />
        <PrivateRoutes
          path="/energy-production"
          exact
          component={EnergyProductionNew}
        />
        <PrivateRoutes path="/emobility" exact component={EmobilityNew} />
        <PrivateRoutes path="/details" exact component={Compare} />
        <PrivateRoutes path="/co2emission" exact component={Co2Emission} />
      </Switch>
    </Router>
  );
};
export default Routes;
