import { ClockCircleFilled } from "@ant-design/icons";
const TooltipComp = ({ data, hollow, units, fields, withoutTitle, from }) => {
  // console.log("data", data);
  return (
    <div className="tooltip-comp">
      <div className="tooltip-bottom" />
      {from === "country" && (
        <p
          style={{
            // fontWeight: "bold",
            marginBottom: "6px",
            fontSize: "15px",
            color: "black",
          }}
        >
          <>
            <ClockCircleFilled
              style={{
                // marginLeft: "10px",
                marginRight: "5px",
                fontSize: "12px",
              }}
            />
            {data?.[0]?.data?.interval}
          </>
        </p>
      )}
      {data?.map((item, index) => (
        <div className="tooltip-item" key={index}>
          <div className="tooltip-left">
            {/* {console.log("item ", item)} */}
            <div
              className="circle-dot"
              style={{ backgroundColor: `${item.color}` }}
            >
              {hollow && <div className="hollow-circle" />}
            </div>
            {!withoutTitle && <span className="head">{fields[index]}:</span>}
          </div>
          <span className="span-value">{`${Number(
            Number(item.value)?.toFixed(2)
          )?.toLocaleString("de-DE")} ${units[index]}`}</span>
        </div>
      ))}
    </div>
  );
};

export default TooltipComp;
