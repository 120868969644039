import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { getHeatmap, getSubChannelHeatmap } from "../../redux";
import moment from "moment";
import { Tooltip, Modal, Typography, Dropdown, Menu, Space } from "antd";
import Loader from "../Loader";
import SubChannelsHeatmap from "./SubChannelsHeatmap";
import { DownOutlined } from "@ant-design/icons";
import { setDisplay } from "../../redux";
import { useSelector, useDispatch } from "react-redux";
import { ClockCircleFilled } from "@ant-design/icons";
import {
  SET_DISPLAY_ANNOTATION,
  SET_DISPLAY_ANNOTATION_SUB,
} from "../../redux/types/generalTypes";

export default function CUSTOMHM({
  filter,
  // displayAnnotation,
  // setDisplayAnnotation,
}) {
  const displayAnnotation = useSelector(
    (state) => state.userReducer.displayAnnotation
  );
  const displayAnnotationSub = useSelector(
    (state) => state.userReducer.displayAnnotationSub
  );
  const ref = useRef(null);
  const [height, setHeight] = useState(50);
  const labelRef = useRef(null);
  const [labelWidth, setLabelWidth] = useState(50);
  const [clientX, setClientx] = useState(0);
  const [clientY, setClientY] = useState(0);
  const [currentInd, setCurrentInd] = useState(null);
  // const [displayAnnotation, setDisplayAnnotation] = useState(false);
  const [selectedItem, setItem] = useState("");

  const [heatMapData, setHeatMapData] = useState([]);
  const [maxNum, setMaxNum] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState(false);
  const [browser, setBrowser] = useState("");

  const [heatmapFilter, setHeatmapFilter] = useState(filter);

  const heatmapRef = useRef(null);

  const dispatch = useDispatch();

  const showModal = (name) => {
    setName(name);
    setIsModalOpen(true);
    dispatch(setDisplay());
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    dispatch(setDisplay());
  };

  const setDate = () => {
    var getDateArray = function (start, end) {
      var arr = [];
      let startDate = new Date(start);
      let endDate = new Date(end);
      while (startDate <= endDate) {
        arr.push(new Date(startDate).toISOString().slice(0, 10));
        startDate.setDate(startDate.getDate() + 1);
      }
      return arr;
    };
    var dateArr = getDateArray(
      moment().subtract(5, "days").format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD")
    );
    let intervals = ["0-6", "6-12", "12-18", "18-24"];
    let hours = [];
    //Mon 0-6
    dateArr?.map((date) => {
      intervals?.map((interval, ind) => {
        hours.push(moment(date).startOf("day").format("ddd") + " " + interval);
      });
    });
    return hours;
  };

  const getLast24Days = () => {
    var getDateArray = function (start, end) {
      var arr = [];
      let startDate = new Date(start);
      let endDate = new Date(end);
      while (startDate <= endDate) {
        arr.push(moment(startDate).format("DD"));
        startDate.setDate(startDate.getDate() + 1);
      }
      return arr;
    };
    var dateArr = getDateArray(
      moment().subtract(23, "days").format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD")
    );

    return dateArr;
  };

  const getData = async () => {
    const result = await getHeatmap({ filter: filter.key });
    setMaxNum(result?.maxNum);
    setHeatMapData([
      ...result?.result,
      filter.key === "24h"
        ? {
            name: "",
            values: [
              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
              20, 21, 22, 23, 24,
            ],
          }
        : filter.key === "6d"
        ? {
            name: "",
            values: setDate(),
            // values: [
            //   "6h",
            //   "12h",
            //   "18h",
            //   "1d",
            //   "1d6h",
            //   "1d12h",
            //   "1d18h",
            //   "2d",
            //   "2d6h",
            //   "2d12h",
            //   "2d18h",
            //   "3d",
            //   "3d6h",
            //   "3d12h",
            //   "3d18h",
            //   "4d",
            //   "4d6h",
            //   "4d12h",
            //   "4d18h",
            //   "5d",
            //   "5d6h",
            //   "5d12h",
            //   "5d18h",
            //   "6d",
            // ].reverse(),
          }
        : {
            name: "",
            values: getLast24Days(),
            // values: [
            //   "1d",
            //   "2d",
            //   "3d",
            //   "4d",
            //   "5d",
            //   "6d",
            //   "7d",
            //   "8d",
            //   "9d",
            //   "10d",
            //   "11d",
            //   "12d",
            //   "13d",
            //   "14d",
            //   "15d",
            //   "16d",
            //   "17d",
            //   "18d",
            //   "19d",
            //   "20d",
            //   "21d",
            //   "22d",
            //   "23d",
            //   "24d",
            // ].reverse(),
          },
    ]);
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsData(!isData);
  //   }, 1000);
  //   if (heatMapData?.length > 0) {
  //     setIsData(!isData);
  //   }
  // }, [isData]);

  useEffect(() => {
    setHeatMapData([]);
    getData();
    setHeight(ref?.current?.offsetWidth * 1.33);
    setHeatmapFilter(filter);
  }, [filter]);

  // useEffect(() => {
  //   console.log("heatmapFilter ", heatmapFilter);
  // }, [heatmapFilter]);

  // useEffect(() => {
  //   // let userAgent = navigator.userAgent;
  //   // let browserName;

  //   // if (userAgent.match(/chrome|chromium|crios/i)) {
  //   //   browserName = "chrome";
  //   // } else if (userAgent.match(/firefox|fxios/i)) {
  //   //   browserName = "firefox";
  //   // } else if (userAgent.match(/safari/i)) {
  //   //   browserName = "safari";
  //   // } else if (userAgent.match(/opr\//i)) {
  //   //   browserName = "opera";
  //   // } else if (userAgent.match(/edg/i)) {
  //   //   browserName = "edge";
  //   // } else {
  //   //   browserName = "No browser detection";
  //   // }
  //   // setBrowser(browserName);
  //   const interval = setInterval(() => {
  //     // if(!navigator.userAgent.includes("Safari")){
  //     // console.log("in interval");
  //     setDisplayAnnotation(false);
  //     // }
  //   }, 500);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  // console.log(displayAnnotation);

  // useEffect(() => {
  //   let interval;
  //   if (browser !== "safari") {
  //     interval = setInterval(() => {
  //       // console.log("in interval")
  //       setDisplayAnnotation(false);
  //     }, 500);
  //   }
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  useLayoutEffect(() => {
    // console.log(ref?.current?.offsetWidth);
    // setHeight(ref?.current?.offsetWidth * 1.33);

    setHeight(ref?.current?.offsetWidth * 1.33);
  }, [ref?.current?.offsetWidth]);

  const Annotation = ({ name, totalLength }) => {
    // console.log("opacity : ", item.value / totalLength);
    return (
      <>
        {displayAnnotation && (
          <>
            <div
              style={{
                filter: "drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5))",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                flex: 1,
                height: 65,
                width: 240,
                boxShadow: "rgb(174 174 174) 0px 0px 10px",
                transition:
                  "left 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s, top 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s",
                color: "rgb(89, 89, 89)",
                backgroundColor: "white",
                fontFamily: "Poppins",
                // color: "white",
                //   // left: clientX,
                left: clientX - 100,
                top: clientY - 100,
                position: "fixed",
                borderRadius: "4px",
                padding: "0 20px",
                fontSize: "10px",
                paddingTop: "5px",
                opacity: selectedItem.name === "" ? 0 : 1,
                zIndex: 100000,
              }}
            >
              <div
                className="tooltip-position"
                style={{ bottom: "-59px", marginLeft: "-15px" }}
              />

              <div
                style={{
                  // fontWeight: "bold",
                  // fontSize: "13px",
                  // marginBottom: "5px",
                  display: "flex",
                  alignItems: "center",
                  // color: "black",
                }}
              >
                {filter?.key == "24h" ? (
                  <>
                    {" "}
                    <p
                      style={{
                        fontSize: "13px",
                        fontWeight: 500,
                        color: "black",
                        marginTop: "2px",
                      }}
                    >
                      {" "}
                      {selectedItem?.title?.split(",")[0]}
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        fontWeight: 400,
                        color: "black",
                      }}
                    >
                      {" "}
                      <ClockCircleFilled
                        style={{
                          marginLeft: "15px",
                          marginRight: "3px",
                        }}
                      />
                      {selectedItem?.title?.split(",")[1]}
                    </p>
                  </>
                ) : (
                  selectedItem?.title
                )}
                {/* 10 - 11 AM */}
              </div>
              {/* <p>{title}</p> */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    background: "#37A1DB",
                    width: 11,
                    height: 11,
                    borderRadius: "50%",
                    opacity: selectedItem.opacity,
                    marginBottom: 0,
                    marginRight: 10,
                  }}
                />
                <p
                  style={{
                    marginBottom: 0,
                    width: "35px",
                    // marginRight: "20px",
                    width: "45%",
                    fontWeight: "500",
                    fontSize: "13px",
                    color: "black",
                  }}
                >
                  {selectedItem.name}
                </p>
                <p
                  style={{
                    color: "black",
                    fontWeight: 400,
                    // color: "#7E84A3",
                    fontSize: "12px",
                    marginBottom: 0,
                  }}
                >
                  {selectedItem?.value?.toLocaleString("de-DE")} kW
                </p>

                {/* <p style={{ marginBottom: "0" }}>
              {value} {name}
            </p> */}
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  const BOX = ({
    item,
    value,
    numberOnly,
    totalLength,
    itemObj,
    arrayLength,
  }) => {
    return (
      <div
        onMouseOver={(e) => {
          setCurrentInd(value);
          // setDisplayAnnotation(true);
          dispatch({ type: SET_DISPLAY_ANNOTATION, payload: true });
          setClientx(e.clientX);
          setClientY(e.clientY);
          setItem({
            ...itemObj,
            opacity: item / totalLength,
          });
        }}
        // onPointerOver={() => {
        //   setDisplayAnnotation(true);
        // }}
        // onPointerOut={() => {
        //   setDisplayAnnotation(false);
        // }}
        // onMouseOver={(e) => {
        //   setCurrentInd(value);
        //   setDisplayAnnotation(true);
        //   setClientx(e.clientX);
        //   setClientY(e.clientY);
        //   setItem({
        //     ...itemObj,
        //     opacity: item / totalLength,
        //   });
        // }}
        ref={ref}
        style={{
          width: `calc(100% / 24)`,
          // width: `${Math.floor(100 / arrayLength)}%`,
          // height: "40px",
          height: numberOnly && filter.key === "6d" ? "80px" : "40px",
          backgroundColor: numberOnly ? "#fff" : "#37A1DB",
          opacity: numberOnly ? 1 : item / maxNum, // we will divide value by max value
          borderRadius: "6px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid white",
          color: "#7E84A3",
          margin: 1.5,
          textAlign: "center",
          // marginTop: numberOnly ? "8px" : "auto",
          fontSize: "14px",
          writingMode:
            numberOnly && filter.key === "6d" ? "vertical-rl" : "horizontal-tb",
          // transform: numberOnly
          //   ? filter.key === "6d"
          //     ? "rotate(90deg)"
          //     : "rotate(0deg)"
          //   : "rotate(0deg)",
        }}
      >
        <span>{numberOnly ? value : ""}</span>
      </div>
    );
  };

  return (
    <div>
      {heatMapData?.length > 0 ? (
        <>
          <Annotation />
          <div style={{ overflow: "auto" }}>
            <div style={{ minWidth: 600 }}>
              {heatMapData?.map((item, index) => {
                return (
                  <div style={{ display: "flex" }} key={index}>
                    <div
                      ref={labelRef}
                      style={{
                        minWidth: 100,
                        maxWidth: 100,
                        display: "flex",
                        alignItems: "center",
                        color: "#131523",
                        marginRight: "10px",
                        fontSize: "12px",
                        cursor: "pointer",
                      }}
                      onClick={() => showModal(item.name)}
                      onMouseOver={(e) => {
                        dispatch({
                          type: SET_DISPLAY_ANNOTATION,
                          payload: false,
                        });
                      }}
                    >
                      {item.name}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        // margin: 1,
                      }}
                    >
                      {item.values.map((itemC, ind) => {
                        return (
                          <>
                            <BOX
                              key={ind}
                              // item={Math.random() * 20}
                              item={itemC.value ? Number(itemC.value) : itemC}
                              value={itemC.value ? Number(itemC.value) : itemC}
                              // name={item.name}
                              numberOnly={item.name === ""}
                              arrayLength={item.values.length}
                              totalLength={Math.max(
                                ...item.values.map((o) => Number(o.value))
                              )}
                              itemObj={{
                                name: item.name,
                                value: itemC.value
                                  ? Number(itemC.value)
                                  : itemC,
                                title: itemC?.time,
                              }}
                            />
                          </>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      ) : (
        <div className="loading">
          <Loader />
        </div>
      )}
      <Modal
        footer={false}
        width={1200}
        centered
        open={isModalOpen}
        onOk={handleOk}
        mask={false}
        onCancel={handleCancel}
        maskClosable={true}
        closeIcon={<></>}
        style={{ marginLeft: "200px" }}
        // style={{ margin: "100px 0 0 200px!important" }}
      >
        <div className="electric-consumption-outer" ref={heatmapRef}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1.5rem",
            }}
            onMouseOver={(e) => {
              dispatch({
                type: SET_DISPLAY_ANNOTATION_SUB,
                payload: false,
              });
            }}
          >
            <Typography.Title
              className="title"
              level={5}
              style={{
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              {name} Electric Consumption Heatmap
            </Typography.Title>
            <Dropdown
              overlay={
                <Menu
                  onClick={(e) => {
                    setHeatmapFilter({
                      value: e.domEvent.target.innerText,
                      key: e.key,
                    });
                  }}
                >
                  <Menu.Item key={"24h"} value="Last Day">
                    Last Day
                  </Menu.Item>
                  <Menu.Item key={"6d"} value="Last 6 Days">
                    Last 6 Days
                  </Menu.Item>
                  <Menu.Item key={"24d"} value="Last 24 Days">
                    Last 24 Days
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <div onClick={(e) => e.preventDefault()}>
                <Space className="electric-consumption-outer-dropdown">
                  {heatmapFilter?.value}
                  <DownOutlined />
                </Space>
              </div>
            </Dropdown>
          </div>
          <SubChannelsHeatmap filter={heatmapFilter} name={name} />
        </div>
      </Modal>
    </div>
  );
}
