import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Typography, Dropdown, Menu, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import Layout from "../Layout/MainLayout";
import Column from "../Components/charts/Column";
import ElectricCard from "../Components/cards/ElectricCard";
import EnergyCostCard from "../Components/cards/EnergyCostCard";
import TotalElectricConsumption from "../Components/cards/TotalElectricConsumption";
import CUSTOMHM from "../Components/charts/CUSTOMHM";
import HorizontalBar from "../Components/charts/HorizontalBar";
import PvProductionModal from "../Components/modal/PvProductionModal";
import {
  getPowerQualtiy,
  getPvProduction,
  getPvProductionOnClick,
  createLog,
  setDisplay,
} from "../redux";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ElectricCardMain from "../Components/cards/ElectricCardMain";
import { SET_DISPLAY_ANNOTATION } from "../redux/types/generalTypes";

const Home = () => {
  const heatmapRef = useRef(null);
  const dispatch = useDispatch();

  const restrictions = useSelector((state) => state.userReducer.restrictions);

  const [pvHeight, setPvHeight] = useState(150);

  const [pvProduction, setPvProduction] = useState(null);
  const [pvProductionConsumption, setPvProductionConsumption] = useState(null);
  const [powerQuality, setPowerQualtity] = useState(null);

  const [heatmapFilter, setHeatmapFilter] = useState({
    value: "Last Day",
    key: "24h",
  });
  const [pvFilter, setPvFilter] = useState({
    value: "Last Day",
    key: "lastday",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  // const [displayAnnotation, setDisplayAnnotation] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
    dispatch(setDisplay());
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    dispatch(setDisplay());
  };

  const { t } = useTranslation();

  const getData = async () => {
    const pvResult = await getPvProduction({ filter: pvFilter.key });
    const res = await getPvProductionOnClick({ filter: pvFilter.key });
    // console.log("pvResultonClick ", res);
    // const powerResult = await getPowerQualtiy();
    // setPowerQualtity(powerResult);
    setPvProduction(pvResult);
    setPvProductionConsumption(res);
  };

  const getPowerQualtiyData = async () => {
    const powerResult = await getPowerQualtiy();
    setPowerQualtity(powerResult);
  };

  // useEffect(() => {
  // setInterval(() => {
  //   getPowerQualtiyData();
  // }, 2000);
  // }, []);

  useEffect(() => {
    getData();
    getPowerQualtiyData();

    // setInterval(() => {
    //   getData();
    // }, 2000);
  }, [pvFilter]);
  useEffect(() => {
    setPvHeight(heatmapRef?.current?.offsetHeight);
    const timeout = setTimeout(() => {
      setPvHeight(heatmapRef?.current?.offsetHeight);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [heatmapRef?.current?.offsetHeight, heatmapFilter]);

  // console.log("electricHeight", electricHeight);

  const history = useHistory();

  const paths = [
    "/",
    "/live",
    // "/analysis",
    "/energy-production",
    "/load-peak",
    // "/digitalization",
    "/emobility",
  ];
  const randomLoop = (index) => {
    if (index > paths.length - 1) {
      return index % paths.length;
    } else {
      return index;
    }
  };
  let user = localStorage.getItem("userId");
  let redirectswitch = localStorage.getItem("redirectswitch");
  useEffect(() => {
    // console.log(redirectswitch);
    // console.log("condition");
    // console.log(
    //   user === "634a977b42241167ecdf2b90" && redirectswitch == "true"
    // );
    if (user === "634a977b42241167ecdf2b90" && redirectswitch == "true") {
      setInterval(() => {
        //demo 6
        const currentPath = window.location.pathname;
        // console.log("currentPath", currentPath);
        const newPath = paths.findIndex((obj) => {
          return obj === currentPath;
        });
        // console.log("newPath index", newPath);
        // console.log("newPath", paths[newPath + 1]);
        history.push(paths[randomLoop(newPath + 1)]);
        localStorage.setItem("redirectswitch", false);
      }, 15000);
    }
    // history.push("live");
  }, []);

  return (
    <Layout active="dashboard">
      {/* <LoginPopup show /> */}
      <div className="home-main">
        <Row gutter={[30, 30]}>
          <Col sm={24} md={24} lg={12} style={{ width: "100%" }}>
            <ElectricCardMain />
            {/* <ElectricCard /> */}
          </Col>
          <Col sm={24} md={24} lg={12} style={{ width: "100%" }}>
            <EnergyCostCard />
          </Col>
          <Col sm={24} md={24} lg={24} style={{ width: "100%" }}>
            <TotalElectricConsumption />
          </Col>
          <Col sm={24} md={24} lg={24} xl={16} style={{ width: "100%" }}>
            <div className="electric-consumption-outer" ref={heatmapRef}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1.5rem",
                }}
                onMouseOver={(e) => {
                  dispatch({ type: SET_DISPLAY_ANNOTATION, payload: false });
                }}
              >
                <Typography.Title
                  className="title"
                  level={5}
                  style={{ fontSize: "16px", fontWeight: "600" }}
                >
                  {t("heatmap")}
                  {/* Electric Consumption Heatmap */}
                </Typography.Title>
                <Dropdown
                  overlay={
                    <Menu
                      onClick={(e) => {
                        setHeatmapFilter({
                          value: e.domEvent.target.innerText,
                          key: e.key,
                        });
                        createLog({
                          page: "home",
                          section: "electric consumption heatmap",
                          filter: e.key,
                          description:
                            "Change filter to " + e.domEvent.target.innerText,
                        });
                      }}
                    >
                      <Menu.Item key={"24h"} value="Last Day">
                        Last Day
                      </Menu.Item>
                      <Menu.Item key={"6d"} value="Last 6 Days">
                        Last 6 Days
                      </Menu.Item>
                      <Menu.Item key={"24d"} value="Last 24 Days">
                        Last 24 Days
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <div onClick={(e) => e.preventDefault()}>
                    <Space className="electric-consumption-outer-dropdown">
                      {heatmapFilter.value}
                      <DownOutlined />
                    </Space>
                  </div>
                </Dropdown>
              </div>
              <CUSTOMHM
                filter={heatmapFilter}
                // displayAnnotation={displayAnnotation}
                // setDisplayAnnotation={setDisplayAnnotation}
              />
              {/* <HeatMap /> */}
            </div>
          </Col>

          <Col sm={24} md={24} lg={24} xl={8} style={{ width: "100%" }}>
            <div
              className="electric-consumption-outer"
              onMouseOver={(e) => {
                dispatch({ type: SET_DISPLAY_ANNOTATION, payload: false });
                // setDisplayAnnotation(false);
              }}
              // style={{ height: pvHeight }}
              style={{
                // height: "350px",
                height: pvHeight,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1.5rem",
                }}
              >
                <PvProductionModal
                  pvProduction={restrictions?.pvProduction}
                  data={pvProductionConsumption}
                  showModal={showModal}
                  isModalOpen={isModalOpen}
                  handleCancel={handleCancel}
                  handleOk={handleOk}
                  pvFilter={pvFilter}
                />
                {restrictions?.pvProduction && (
                  <Dropdown
                    overlay={
                      <Menu
                        onClick={(e) => {
                          setPvFilter({
                            value: e.domEvent.target.innerText,
                            key: e.key,
                          });
                          createLog({
                            page: "home",
                            section: "PV production",
                            filter: e.key,
                            description:
                              "Change filter to " + e.domEvent.target.innerText,
                          });
                        }}
                      >
                        <Menu.Item key={"lastday"} value="Last Day">
                          Last Day
                        </Menu.Item>
                        <Menu.Item key={"lastweek"} value="Last Week">
                          Last Week
                        </Menu.Item>
                        <Menu.Item key={"lastmonth"} value="Last Month">
                          Last Month
                        </Menu.Item>
                        <Menu.Item key={"lastyear"} value="Last Year">
                          Last Year
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <div onClick={(e) => e.preventDefault()}>
                      <Space className="electric-consumption-outer-dropdown">
                        {pvFilter.value}
                        <DownOutlined />
                      </Space>
                    </div>
                  </Dropdown>
                )}
              </div>
              <div
                className="column-chart"
                style={{
                  // height: `140px`,
                  // height: `250px`,
                  height: `${pvHeight - 90}px`,
                }}
                onClick={showModal}
              >
                {restrictions?.pvProduction ? (
                  <Column
                    Data={pvProduction ? pvProduction : []}
                    label
                    time={pvFilter?.value}
                  />
                ) : (
                  <HorizontalBar Data={powerQuality ? powerQuality : []} />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default Home;
