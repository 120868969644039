import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Mix } from "@ant-design/plots";
import TooltipComp from "../TooltipComp";
const DemoMix = () => {
  const averageData = [
    {
      date: "2015-02",
      EnExpert: 1,
    },
    {
      date: "2015-08",
      EnExpert: 7,
    },
    {
      date: "2016-01",
      EnExpert: 2,
    },
    {
      date: "2017-02",
      EnExpert: 11,
    },
    {
      date: "2018-01",
      EnExpert: 4,
    },
    {
      date: "2018-08",
      EnExpert: 12,
    },
    {
      date: "2019-02",
      EnExpert: 1,
    },
    {
      date: "2019-08",
      EnExpert: 12,
    },
    {
      date: "2020-01",
      EnExpert: 5,
    },
    {
      date: "2020-02",
      EnExpert: 10,
    },
    {
      date: "2021-01",
      EnExpert: 11,
    },
    {
      date: "2022-08",
      EnExpert: 2,
    },
    // {
    //   date: '2015-02',
    //   value: 21168,
    // },
    // {
    //   date: '2015-08',
    //   value: 21781,
    // },
    // {
    //   date: '2016-01',
    //   value: 23818,
    // },
    // {
    //   date: '2017-02',
    //   value: 25316,
    // },
    // {
    //   date: '2018-01',
    //   value: 26698,
    // },
    // {
    //   date: '2018-08',
    //   value: 27890,
    // },
  ];
  const config = {
    appendPadding: 8,
    // tooltip: {
    //   shared: true,
    // },
    tooltip: {
      offset: 50,
      reversed: true,
      showCrosshairs: false,
      shared: true,
      domStyles: {
        "g2-tooltip": {
          background: "transparent",
          minWidth: "300px",
          maxWidth: "300px",
          borderRadius: 0,
          padding: "0",
        },
      },
      customContent: (title, data) => {
        if (data) {
          return (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  // height: 80,
                  // width: 250,
                  color: "rgb(89, 89, 89)",
                  backgroundColor: "white",
                  // backgroundColor: "#131523",
                  fontFamily: "Poppins",
                  borderRadius: "4px",
                  padding: "0 20px",
                  fontSize: "10px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  filter: "drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5))",
                  position: "relative",
                  minHeight: "auto",
                  minWidth: "auto",
                  borderRadius: "8px",
                }}
              >
                <div className="tooltip-bottom" />

                {data?.map((itm) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "5px 0",
                      }}
                    >
                      <p
                        style={{
                          // background: itm?.color,
                          background: "trasnparent",
                          border: `3px solid ${itm?.color}`,
                          width: 12,
                          height: 12,
                          borderRadius: "50%",
                          marginBottom: 0,
                          marginRight: 10,
                        }}
                      />
                      <p
                        style={{
                          marginBottom: 0,
                          width: "150px",
                          // width: "35%",
                          fontSize: "15px",

                          fontWeight: "400",
                          color: "black",
                        }}
                      >
                        {itm?.name}
                      </p>
                      <p
                        style={{
                          color: "black",
                          fontWeight: "500",
                          fontSize: "15px",
                          marginBottom: 0,
                          width: "100px",
                        }}
                      >
                        {itm?.value?.toLocaleString("de-DE")} {" kW"}
                      </p>
                    </div>
                  </>
                ))}
              </div>
            </>
          );
        }
      },
    },
    syncViewPadding: true,
    plots: [
      {
        type: "column",
        options: {
          data: [
            {
              date: "2015-02",
              "Energy Usage": 3,
            },
            {
              date: "2015-08",
              "Energy Usage": 4,
            },
            {
              date: "2016-01",
              "Energy Usage": 10,
            },
            {
              date: "2017-02",
              "Energy Usage": 11,
            },
            {
              date: "2018-01",
              "Energy Usage": 8,
            },
            {
              date: "2018-08",
              "Energy Usage": 9,
            },
            {
              date: "2019-02",
              "Energy Usage": 7,
            },
            {
              date: "2019-08",
              "Energy Usage": 12,
            },
            {
              date: "2020-01",
              "Energy Usage": 5,
            },
            {
              date: "2020-02",
              "Energy Usage": 7,
            },
            {
              date: "2021-01",
              "Energy Usage": 6,
            },
            {
              date: "2022-08",
              "Energy Usage": 8,
            },
          ],
          xField: "date",
          yField: "Energy Usage",
          yAxis: {
            type: "log",
            max: 15,
          },
          // #EDEDED
          color: "#D4D4D4",
          maxColumnWidth: 8,
          //   meta: {
          //     date: {
          //       sync: true,
          //     },
          //     value: {
          //       alias: "店数(间)",
          //     },
          //   },
          //   label: {
          //     position: "middle",
          //   },
        },
      },
      {
        type: "line",
        options: {
          data: averageData,
          xField: "date",
          yField: "EnExpert",
          xAxis: false,
          yAxis: {
            type: "log",
            max: 15,
          },
          // label: {
          //   offsetY: -8,
          // },
          //   meta: {
          //     value: {
          //       alias: "平均租金(元)",
          //     },
          //   },
          color: "#2EC276",
          lineStyle: {
            lineWidth: 3,
            lineDash: [5, 5],
          },
        },
      },
      {
        type: "line",
        options: {
          data: [
            {
              date: "2015-02",
              market: 1,
            },
            {
              date: "2015-08",
              market: 2.9,
            },
            {
              date: "2016-01",
              market: 9.4,
            },
            {
              date: "2017-02",
              market: 14.8,
            },
            {
              date: "2018-01",
              market: 5.5,
            },
            {
              date: "2018-08",
              market: 4.5,
            },
            {
              date: "2019-02",
              market: 1,
            },
            {
              date: "2019-08",
              market: 2.9,
            },
            {
              date: "2020-01",
              market: 9.4,
            },
            {
              date: "2020-02",
              market: 14.8,
            },
            {
              date: "2021-01",
              market: 5.5,
            },
            {
              date: "2021-08",
              market: 4.5,
            },
          ],
          xField: "date",
          yField: "market",
          xAxis: false,
          yAxis: {
            type: "log",
            max: 15,
          },
          // meta: {
          //   date: {
          //     sync: 'date',
          //   },
          //   value: {
          //     alias: '递增',
          //     formatter: (v) => `${(v * 100).toFixed(1)}%`,
          //   },
          // },
          smooth: true,
          // label: {
          //   callback: (value) => {
          //     return {
          //       offsetY: value === 0.148 ? 36 : value === 0.055 ? 0 : 20,
          //       style: {
          //         fill: '#1AAF8B',
          //         fontWeight: 700,
          //         stroke: '#fff',
          //         lineWidth: 1,
          //       },
          //     };
          //   },
          // },
          color: "#DD243B",
        },
      },
    ],
  };
  return <Mix {...config} />;
};

export default DemoMix;
// ReactDOM.render(<DemoMix />, document.getElementById('container'));
