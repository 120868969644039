import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Column } from "@ant-design/plots";
import { FaSleigh } from "react-icons/fa";
import moment from "moment";
import { ClockCircleFilled } from "@ant-design/icons";

const DemoColumn = ({ Data, Alias, label, unit, time }) => {
  // console.log("pvResult ", Data);

  const config = {
    data: Data,
    xField: "type",
    yField: "sales",
    autoFit: true,
    color: "#37A1DB",

    tooltip: {
      offset: 80,
      domStyles: {
        "g2-tooltip": {
          background: "transparent",
          minWidth: "250px",
          maxWidth: "250px",
          borderRadius: 0,
          padding: "0",
        },
      },
      customContent: (title, data) => {
        return (
          <>
            {/* {displayAnnotation && ( */}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                filter: "drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5))",
                position: "relative",
                color: "rgb(89, 89, 89)",
                backgroundColor: "white",
                fontFamily: "Poppins",
                borderRadius: "4px",
                padding: "0 20px",
                paddingTop: "10px",
                paddingBottom: "10px",
                fontSize: "10px",
                minHeight: "auto",
                minWidth: "auto",
              }}
            >
              <div className="tooltip-polygon" />
              <div
                style={{
                  // fontWeight: "bold",
                  // fontSize: "13px",
                  // color: "black",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {time == "Last Day" ? (
                  <>
                    <p
                      style={{
                        fontSize: "13px",
                        fontWeight: 500,
                        color: "black",
                        marginTop: "3px",
                      }}
                    >
                      {data?.[0]?.data?.interval.split(",")[0]}
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        fontWeight: 400,
                        color: "black",
                      }}
                    >
                      {" "}
                      <ClockCircleFilled
                        style={{
                          marginLeft: "15px",
                          marginRight: "3px",
                        }}
                      />
                      {data?.[0]?.data?.interval.split(",")[1]}
                    </p>
                  </>
                ) : (
                  data?.[0]?.data?.interval
                )}

                {/* 10 - 11 AM */}
              </div>
              {/* <p>{title}</p> */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* {console.log("clr ", data?.[0])} */}
                <p
                  style={{
                    background: data?.[0]?.color,
                    width: 10,
                    height: 10,
                    borderRadius: "50%",
                    // opacity: value / totalLength,
                    marginBottom: 0,
                    marginRight: 10,
                  }}
                />
                <p
                  style={{
                    marginBottom: 0,
                    width: "35px",
                    // marginRight: "20px",
                    width: "45%",
                    fontSize: "13px",

                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  {data?.[0]?.name}
                </p>
                <p
                  style={{
                    color: "black",
                    fontSize: "12px",
                    fontWeight: "400",
                    marginBottom: 0,
                  }}
                >
                  {data?.[0]?.value?.toLocaleString("de-DE")}{" "}
                  {unit ? " " + unit : " kWh"}
                </p>

                {/* <p style={{ marginBottom: "0" }}>
                  {data?.[0]?.name}: {data?.[0]?.value}
                </p> */}
              </div>
            </div>
            {/* )} */}
          </>
        );
      },
    },

    xAxis: {
      // position: "top",
      // tickLine: null,
      line: null,
      // label: label
      //   ? {
      //       formatter: (value) => {
      //         return value % 2 === 0 ? value : "";
      //       },
      //     }
      //   : {
      //       formatter: (value) => {
      //         return value;
      //       },
      //     },

      grid: {
        line: {
          style: {
            stroke: "black",
            lineWidth: 0.1,
          },
        },
        alignTick: true,
        closed: false,
        nice: false,
      },
    },
    // yAxis: false,

    yAxis: {
      // tickLine: null,
      // line: null,
      // position: "bottom",

      title: {
        text: "kWh",
        // style: {
        //   stroke: "#7E84A3",
        //   fontSize: 14,
        //   letterSpacing: "1px",
        //   fontWeight: 400,
        //   opacity: 0.7,
        //   shadowBlur: 0,
        //   strokeOpacity: 0,
        // },
        style: {
          stroke: "#000000",
          fontSize: 13,
          color: "#000000",
          letterSpacing: "1px",
          fontWeight: 300,
          opacity: 0.9,
          shadowBlur: 0,
          strokeOpacity: 0,
        },
      },
      // label: {
      //   formatter: (value) => {
      //     return value;
      //     // return value + `${unit ? " " + unit : " kWh"}`;
      //   },
      // },
      grid: {
        line: {
          style: {
            stroke: "black",
            lineWidth: 0,
          },
        },
        alignTick: true,
        closed: true,
      },
    },
    meta: {
      sales: {
        alias: Alias ? Alias : "Production",
      },
    },
    interactions: [
      {
        type: "active-region",
        enable: false,
      },
    ],
    minColumnWidth: 4,
    maxColumnWidth: 4,
    // columnBackground: {
    //   style: {
    //     fill: "l(0) 0:#fff  0.3:#fff 0.4:#E6E9F4 0.5:#000 0.6:#E6E9F4 0.7:#fff 1:#fff",
    //   },
    // },
    columnStyle: {
      fill: "#37A1DB",
      // fill: "r(0, 0.9, 1) 0:#37A1DB 0.9:#37A1DB 1:#fff",
      fillOpacity: 1,
      radius: [20, 20, 0, 0],
    },
  };
  return <Column {...config} animation={false} />;
};

export default DemoColumn;
