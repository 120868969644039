import React, { useEffect } from "react";
import { Typography, DatePicker, Button } from "antd";
import { AiOutlineRight } from "react-icons/ai";
import { Line } from "@ant-design/plots";

const LiveElectricConsumption = ({ data, colors }) => {
  // useEffect(() => {
  //   console.log("color change ", colors);
  // }, [colors]);

  // useEffect(() => {
  //   console.log("data change ", data);
  // }, [data]);

  const config = {
    data: data,
    autoFit: true,
    height: 300,
    xField: "date",
    yField: "value",
    seriesField: "name",
    color: colors,

    legend: false,

    yAxis: {
      tickInterval: 10,
      title: {
        text: "kw",
        style: {
          fontWeight: 500,
          fontSize: 18,
        },
      },
      label: {
        formatter: (v) =>
          `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },

    // tooltip: {
    //   title: (datum, data) => {
    //     // console.log("datum 2", data);
    //     return datum;
    //   },
    //   formatter: (datum) => {
    //     return { name: datum.name, value: datum.value + " kW" };
    //   },
    // },
    tooltip: {
      domStyles: {
        "g2-tooltip": {
          minWidth: "220px",
          maxWidth: "220px",
          color: "black",
          opacity: 1,
          padding: "0",

          // borderRadius: "8px",
        },
      },
      customContent: (title, data) => {
        console.log(data);
        return (
          <div
            className="tooltip-comp"
            style={{
              background: "white",
              width: "260px",
              color: "black",
              filter: "drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5))",
              borderRadius: "8px 8px 0 0",
            }}
          >
            <div
              className="tooltip-position"
              style={{ marginLeft: "-15px", bottom: "-58px", width: "272px" }}
            />
            {/* <div className="tooltip-polygon" /> */}
            <p
              style={{
                fontWeight: 700,
                marginBottom: "12px",
                fontSize: "15px",
                color: "black",
              }}
            >
              {title}
            </p>
            {data?.map((item, index) => (
              <div
                className="tooltip-item"
                key={index}
                style={{ marginBottom: "5px" }}
              >
                <div className="tooltip-left">
                  {/* {console.log("item ", item)} */}
                  <div
                    className="circle-dot"
                    style={{
                      backgroundColor: `${item.color}`,
                      height: "11px",
                      width: "11px",
                    }}
                  ></div>
                  <span
                    className="head"
                    style={{ fontSize: "14px", fontWeight: 500 }}
                  >
                    {item?.name}
                  </span>
                </div>
                <span
                  className="span-value"
                  style={{ fontSize: "13px", fontWeight: 400 }}
                >
                  {item.value}
                </span>
              </div>
            ))}
          </div>
        );
      },
    },
  };

  return (
    <div className="LEC-container">
      <div className="LEC-graph">
        <Line {...config} autoFit animation={false} />
      </div>
    </div>
  );
};

export default LiveElectricConsumption;
