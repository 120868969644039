import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Gauge } from "@ant-design/plots";
import { BsArrowUp } from "react-icons/bs";

const DemoGauge = ({ width = "inherit", height = "inherit" }) => {
  const config = {
    percent: 0.1,
    radius: 0.8,
    range: {
      width: 10,
      color: ["#D9D9D9", "#07A0E0"],
    },
    gaugeStyle: {
      lineCap: "round",
    },
    width: 250,
    height: 250,
    startAngle: Math.PI,
    endAngle: 2 * Math.PI,
    indicator: null,
  };
  return <Gauge {...config} />;
};

export default DemoGauge;
