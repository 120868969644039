import Layout from "../Layout/MainLayout";
import { Typography, Row, Col, Divider } from "antd";
import signalIcon from "../Assets/signal24_red.png";
import snake from "../Assets/snake.png";
import off_rect from "../Assets/off_rect.png";
import EnergyCostCard from "../Components/cards/EnergyCostCard";
import LiquidChart from "../Components/charts/LiquidChart";
import TinyColumn from "../Components/charts/TinyColumn";
import Loader from "../Components/Loader";
import ChartjsSimpleDonut from "../Components/charts/ChartjsSimpleDonut";
import TinyArea from "../Components/charts/TinyArea";
import HeatConsumptionColumn from "../Components/charts/HeatConsumptionColumn";
import HorizontalBar from "../Components/charts/HorizontalBar";
import ElectricConsumptionRoundChart from "../Components/ElectricConsumptionRoundLive";
import { GiCarBattery } from "react-icons/gi";
import { AiFillThunderbolt } from "react-icons/ai";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import {
  getSolarProductionLivePage,
  getHeatConsumption,
  getWaterConsumption,
  getPvProductionLivePage,
  getCo2Consumption,
  getCurrentlySave,
  getStationsData,
} from "../redux";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const LoadPeak = () => {
  const liveConfig = useSelector((state) => state.userReducer.livePageConfig);

  const [batteryPercent, setBatteryPercent] = useState(35);
  const [batteryStatus, setBatteryStatus] = useState("Charging");
  const [batteryForward, setBatteryForward] = useState(true);

  let [statsData, setStatsData] = useState(null);
  let [heatData, setHeatData] = useState(null);
  let [waterData, setWaterData] = useState(null);
  let [saveData, setSaveData] = useState(null);
  let [co2Data, setco2Data] = useState(null);

  let [pvProduction, setPvProduction] = useState(null);
  const [powerQuality, setPowerQualtity] = useState(null);
  const [stationData, setStationData] = useState(null);

  const [electricConsumption, setElectricConsumption] = useState(null);

  let chargingstationsData = [
    {
      name: "Wallbox Garage",
      desc: "1 Phase AC",
    },
    {
      name: "Client Fast Charger",
      desc: "3 Phase AC",
    },
    {
      name: "Super Charger",
      desc: "3 Phase AC",
    },
    {
      name: "Staff Parking",
      desc: "1 Phase AC",
    },
  ];

  useEffect(() => {
    let interval = setInterval(() => {
      if (batteryPercent === 100 && batteryForward) {
        setBatteryStatus("Completed");
        setBatteryForward(false);
      }

      if (batteryPercent === 0) {
        setBatteryPercent((percent) => percent + 1);
        setBatteryStatus("Charging");

        setBatteryForward(true);
      }
      if (batteryForward && batteryPercent < 100) {
        setBatteryPercent((percent) => percent + 1);
        setBatteryStatus("Charging");

        setBatteryForward(true);
      }
      if (!batteryForward && batteryPercent !== 0) {
        setBatteryStatus("Battery in Use");

        setBatteryPercent((percent) => percent - 1);
      }
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [batteryPercent, batteryForward]);

  const getData = async () => {
    const heatData = await getHeatConsumption();
    const waterData = await getWaterConsumption();
    const pvData = await getPvProductionLivePage();
    const save = await getCurrentlySave();
    const co2Data = await getCo2Consumption();
    const data = await getSolarProductionLivePage();
    const station = await getStationsData();
    setHeatData(heatData);
    setWaterData(waterData);
    setPvProduction(pvData);
    setSaveData(save);
    setco2Data(co2Data);
    setStatsData(data);
    setStationData(station);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const timeoutID = setInterval(() => {
      console.log("in interval");
      getData();
    }, 15000);

    return () => {
      clearInterval(timeoutID);
    };
  }, []);

  return (
    <Layout active="live">
      <div className="live-main energy-main general-live-grid">
        <div
          className="live-card white-card current-saving"
          // style={{ minHeight: "200px" }}
        >
          <div className="flex-between">
            <Typography.Title
              className="title  main-title"
              level={5}
              style={{ fontWeight: "500", margin: "0" }}
            >
              You currently save{" "}
            </Typography.Title>
            <img src={signalIcon} />
          </div>

          <div className="percent">
            <Typography.Text
              style={{
                fontWeight: "500",
                fontSize: "1rem",
                marginRight: "10px",
              }}
            >
              {saveData?.total?.toLocaleString("de-DE")} €
            </Typography.Text>
            <span
              style={{
                fontSize: "12px",
                color: "var(--green)",
                fontWeight: "bold",
              }}
            >
              {statsData?.savedPercent + "%"}
              {statsData?.savedPercent < 0 ? (
                <BsArrowDown style={{ strokeWidth: 2 }} />
              ) : (
                <BsArrowUp style={{ strokeWidth: 2 }} />
              )}
            </span>
            &nbsp;
            <span className="secondary">than last hour</span>
          </div>

          <div
            style={{
              width: "100%",
              marginTop: "10px",
              height: "70px",
            }}
          >
            <TinyColumn
              Data={saveData ? saveData?.data : []}
              fields={["time", "value"]}
              alias={"€"}
              unit={"€"}
              color={"#F99600"}
            />
            {/* <ColumnLineDualAxis
                  sliced={7}
                  animate
                  leftUnit="€"
                  rightUnit="€"
                  xUnit="last week"
                  alias={["Line (€)", "Bar (€)"]}
                /> */}
          </div>
        </div>

        <EnergyCostCard
          // height={"150px"}
          unit={"kg"}
          data={co2Data}
          noWater
          fromLive
          header={
            <Typography.Title
              className="title  main-title"
              level={5}
              style={{ fontWeight: "500" }}
            >
              <span>
                CO<sub>2</sub> Consumption
              </span>{" "}
              <img src={signalIcon} />
            </Typography.Title>
          }
        />

        <div className="live-card white-card solar-produCtion">
          <Typography.Title
            className="title  main-title"
            level={5}
            style={{ fontWeight: "500" }}
          >
            Solar Thermal Production <img src={signalIcon} />
          </Typography.Title>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div>
              <div className="percent">
                <Typography.Text
                  style={{
                    fontWeight: "500",
                    fontSize: "1rem",
                    marginRight: "10px",
                  }}
                >
                  {Number(statsData?.thermalProduction)?.toLocaleString(
                    "de-DE"
                  )}{" "}
                  kW
                </Typography.Text>
                <span
                  style={{
                    fontSize: "12px",
                    color: "var(--green)",
                    fontWeight: "bold",
                  }}
                >
                  {statsData?.thermalPercent + "%"}
                  {statsData?.thermalPercent < 0 ? (
                    <BsArrowDown style={{ strokeWidth: 2 }} />
                  ) : (
                    <BsArrowUp style={{ strokeWidth: 2 }} />
                  )}
                </span>
                &nbsp;
                <span className="secondary">than last hour</span>
              </div>
            </div>
            <div
              style={{
                width: "95%",
                marginTop: "10px",
                height: "70px",
              }}
            >
              <TinyArea
                Data={statsData?.thermalData ? statsData?.thermalData : []}
                color={{
                  hex: "#0099FF",
                  rgb: [0, 153, 255],
                }}
                unit="kWh"
                TooltipUnit="kWh"
                noArea
                // fullArea
                fullLine
                fields={["minutes", "value"]}
              />
              {/* <TinyLine
                    Data={statsData?.thermalData ? statsData?.thermalData : []}
                  /> */}
            </div>
          </div>
        </div>

        <div className="white-card live-big-card electric-consumption">
          <Typography.Title
            className="title main-title"
            level={4}
            style={{ fontWeight: "600" }}
          >
            Electric Consumption <img src={signalIcon} />
          </Typography.Title>
          <ElectricConsumptionRoundChart
            electricConsumption={electricConsumption}
          />
        </div>

        <div className="white-card live-big-card heat-consumption">
          <Typography.Title
            className="title main-title"
            level={4}
            style={{ fontWeight: "600", marginBottom: "20px" }}
          >
            Heat Consumption <img src={signalIcon} />
          </Typography.Title>
          <HeatConsumptionColumn
            Data={heatData}
            colors={liveConfig?.heatColors}
          />
        </div>

        <div
          className="white-card live-big-card e-mobility"
          style={{ height: "auto" }}
        >
          <Typography.Title
            className="title main-title"
            level={4}
            style={{ fontWeight: "600", marginBottom: "20px" }}
          >
            E-Mobility
          </Typography.Title>
          <div style={{ width: "90%", margin: "auto" }}>
            {stationData ? (
              <Row gutter={[20, 20]}>
                {stationData?.map((data, ind) => (
                  <Col xs={24} md={24} lg={12} key={ind}>
                    <div
                      className={
                        data.status
                          ? "white-card station-card"
                          : "white-card station-card station-card-gray"
                      }
                    >
                      <img
                        src={data.status ? snake : off_rect}
                        className="snake"
                      />
                      <h5 className="station-card-title">
                        {chargingstationsData[ind].name}
                      </h5>

                      <h6
                        className="station-card-sub-title"
                        style={
                          !data.status
                            ? { color: "inherit", marginBottom: "1rem" }
                            : { color: "#099cff", marginBottom: "1rem" }
                        }
                      >
                        {chargingstationsData[ind].desc}
                      </h6>

                      <div
                        style={{
                          height: "3rem",
                          position: "relative",

                          borderLeft: `1px solid ${
                            data.status ? "#0098FD" : "#A2A2A2"
                          } `,
                          // opacity: `${data.status ? "1" : ".5"}`,
                          // strokeOpacity: `${data.status ? 1 : 0.5}`,
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            left: 0,
                            width: "2rem",
                            height: "1px",
                            backgroundColor: `${
                              data.status ? "#0098FD" : "#A2A2A2"
                            }`,
                          }}
                        ></div>
                        <div
                          style={{
                            position: "absolute",
                            top: "-.5rem",
                            left: "1rem",
                            backgroundColor: "#fff",
                            width: "100%",
                            // width: "80%",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                backgroundColor: `${
                                  data.status ? "#0098FD" : "#A2A2A2"
                                }`,

                                width: "1rem",
                                height: "1rem",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#fff",
                                  width: ".6rem",
                                  height: ".6rem",
                                  borderRadius: "50%",
                                }}
                              ></div>
                            </div>
                            <Typography.Text
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: ".5rem",
                              }}
                            >
                              Status :{" "}
                              {data.status
                                ? `Charging (${(
                                    data.chargedPercent * 100
                                  ).toFixed()}%)`
                                : "Not Charging"}
                            </Typography.Text>
                          </div>
                          <Divider />
                        </div>
                      </div>
                      <div
                        style={{
                          height: "3rem",
                          position: "relative",

                          borderLeft: `1px solid ${
                            data.status ? "#0098FD" : "#A2A2A2"
                          } `,
                          // opacity: `${data.status ? "1" : ".5"}`,
                          // strokeOpacity: `${data.status ? 1 : 0.5}`,
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            left: 0,
                            width: "2rem",
                            height: "1px",
                            backgroundColor: `${
                              data.status ? "#0098FD" : "#A2A2A2"
                            }`,
                          }}
                        ></div>
                        <div
                          style={{
                            position: "absolute",
                            top: "-.5rem",
                            left: "1rem",
                            backgroundColor: "#fff",
                            width: "100%",
                            // width: "80%",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                backgroundColor: `${
                                  data.status ? "#0098FD" : "#A2A2A2"
                                }`,

                                width: "1rem",
                                height: "1rem",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#fff",
                                  width: ".6rem",
                                  height: ".6rem",
                                  borderRadius: "50%",
                                }}
                              ></div>
                            </div>
                            <Typography.Text
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: ".5rem",
                              }}
                            >
                              AMPS : 125 Amps
                            </Typography.Text>
                          </div>
                          <Divider />
                        </div>
                      </div>
                      <div
                        style={{
                          height: "3rem",
                          position: "relative",

                          borderLeft: `1px solid ${
                            data.status ? "#0098FD" : "#A2A2A2"
                          } `,
                          // opacity: `${data.status ? "1" : ".5"}`,
                          // strokeOpacity: `${data.status ? 1 : 0.5}`,
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            left: 0,
                            width: "2rem",
                            height: "1px",
                            backgroundColor: `${
                              data.status ? "#0098FD" : "#A2A2A2"
                            }`,
                          }}
                        ></div>
                        <div
                          style={{
                            position: "absolute",
                            top: "-.5rem",
                            left: "1rem",
                            backgroundColor: "#fff",
                            width: "100%",
                            // width: "80%",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                backgroundColor: `${
                                  data.status ? "#0098FD" : "#A2A2A2"
                                }`,

                                width: "1rem",
                                height: "1rem",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#fff",
                                  width: ".6rem",
                                  height: ".6rem",
                                  borderRadius: "50%",
                                }}
                              ></div>
                            </div>
                            <Typography.Text
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: ".5rem",
                              }}
                            >
                              Remaining : {data.remainingTime}
                            </Typography.Text>
                          </div>
                          <Divider />
                        </div>
                      </div>
                      <div
                        style={{
                          height: "0.5rem",
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            left: 0,
                            width: "2rem",
                            height: "1px",
                            backgroundColor: `${
                              data.status ? "#0098FD" : "#A2A2A2"
                            }`,
                          }}
                        ></div>
                        <div
                          style={{
                            position: "absolute",
                            top: "-.5rem",
                            left: "1rem",
                            backgroundColor: "#fff",
                            width: "100%",
                            // width: "80%",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                backgroundColor: `${
                                  data.status ? "#0098FD" : "#A2A2A2"
                                }`,

                                width: "1rem",
                                height: "1rem",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#fff",
                                  width: ".6rem",
                                  height: ".6rem",
                                  borderRadius: "50%",
                                }}
                              ></div>
                            </div>
                            <Typography.Text
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: ".5rem",
                              }}
                            >
                              Charging Load : {data.load} kW
                            </Typography.Text>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            ) : (
              <div
                className="loading"
                style={{ minHeight: "auto", height: "100%" }}
              >
                <Loader />
              </div>
            )}
          </div>
        </div>

        <div className="white-card battery-level">
          <Typography.Title className="title main-title" level={5}>
            Battery Level <img src={signalIcon} />
          </Typography.Title>
          <div style={{ height: "100%" }}>
            {[
              {
                title: "Battery 1",
                percentage: 0.5,
                status: "Charging...",
              },
            ]?.map((level, index) => (
              <div style={{ height: "11rem" }} key={index}>
                <LiquidChart
                  percentage={batteryPercent / 100}
                  status={batteryStatus}
                  color={liveConfig?.batteryColor}
                />
                <div className="level-section">
                  <Typography.Title
                    level={5}
                    style={{
                      color: "#131523",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    {level.title}
                  </Typography.Title>
                  <Typography.Text
                    type="secondary"
                    style={{
                      fontSize: "13px",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {batteryStatus === "Battery in Use" ? (
                      <GiCarBattery
                        style={{
                          marginRight: "5px",
                        }}
                        // color={
                        //   level.status === "Stopped Charging..."
                        //     ? "red"
                        //     : level.status === "Completed"
                        //     ? ""
                        //     : "green"
                        // }
                      />
                    ) : (
                      <AiFillThunderbolt
                        size={14}
                        style={{
                          marginRight: "5px",
                        }}
                        color={"green"}
                        // color={
                        //   level.status === "Stopped Charging..."
                        //     ? "red"
                        //     : level.status === "Completed"
                        //     ? ""
                        //     : "green"
                        // }
                      />
                    )}{" "}
                    {batteryStatus}
                  </Typography.Text>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div
          className="white-card pv-production"
          style={{ justifyContent: "flex-start" }}
        >
          <Typography.Title className="title main-title" level={5}>
            PV Production <img src={signalIcon} />
          </Typography.Title>

          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
            }}
          >
            <div
              style={{
                width: "45%",
                display: "flex",
                margin: "0 auto",
                position: "relative",
              }}
            >
              <ChartjsSimpleDonut
                height="100%"
                Data={[
                  pvProduction?.channels?.autoconsumption <= 0
                    ? 3
                    : pvProduction?.channels?.autoconsumption,
                  pvProduction?.channels?.grid_feed <= 0
                    ? 3
                    : pvProduction?.channels?.grid_feed,
                  pvProduction?.channels?.batteryCharging <= 0
                    ? 3
                    : pvProduction?.channels?.batteryCharging,
                ]}
                width="100%"
                colors={["#F99600", "#DD243B", "#0099FF"]}
              />
              <p
                style={{
                  position: "absolute",
                  top: "15%",
                  right: "-7rem",
                  textAlign: "center",
                  color: "#F99600",
                  fontSize: "0.75rem",
                }}
              >
                {pvProduction?.channels?.autoconsumption}%
                <br />
                Self consumption
              </p>

              <p
                style={{
                  position: "absolute",
                  top: "15%",
                  left: "-7rem",
                  textAlign: "center",
                  color: "#0099FF",
                  fontSize: "0.75rem",
                }}
              >
                {pvProduction?.channels?.batteryCharging}%
                <br />
                Battery Charging
              </p>
              <p
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  textAlign: "center",
                  width: "fit-content",
                  transform: "translate(-50%,-50%)",
                }}
              >
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: "1.56rem",
                  }}
                >
                  {Number(pvProduction?.totalProduction)?.toLocaleString(
                    "de-DE"
                  )}
                </span>
                <br />
                <span
                  style={{
                    color: "var(--secondaryText)",
                    fontSize: "0.87rem",
                  }}
                >
                  kW
                </span>
              </p>
            </div>
            <p
              style={{
                // position: "absolute",
                // bottom: "-30%",
                // left: "20%",
                marginTop: ".5rem",
                textAlign: "center",
                color: "#DD243B",
                fontSize: "0.75rem",
              }}
            >
              {pvProduction?.channels?.grid_feed}%
              <br />
              Grid-feed
            </p>
          </div>
        </div>

        <div
          className="white-card live-card water-consuption"
          style={{ padding: "5px" }}
        >
          <div style={{ padding: "20px 1rem", zIndex: "100" }}>
            <Typography.Title
              className="title main-title"
              level={4}
              style={{ fontWeight: "600" }}
            >
              {liveConfig?.waterConsumption
                ? "Water Consumption"
                : "Power Quality"}{" "}
              <img src={signalIcon} />
            </Typography.Title>
          </div>
          <div
            style={{
              width: "100%",
            }}
          >
            {liveConfig?.waterConsumption ? (
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "14rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "1.75rem",
                    fontWeight: "600",
                    margin: 0,
                  }}
                >
                  {waterData?.toLocaleString("de-DE")} Liter/s
                </p>
                <div className="ocean">
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                </div>
                {/* <div className="wrapper">
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "1.75rem",
                      fontWeight: "600",
                      margin: 0,
                    }}
                  >
                    {waterData?.toLocaleString("de-DE")} Liter/s
                  </p>
                  <div className="wave"></div>
                  <div className="wrapper2">
                    <div className="wave2"></div>
                  </div>
                  <div className="wrapper3">
                    <div className="wave3"></div>
                  </div>
                </div> */}
                {/* <img src={waves} width="100%" height="100px" /> */}
              </div>
            ) : (
              // <LiquidSquare
              //   percentage={waterData ? waterData : 0}
              //   color={liveConfig?.batteryColor}
              // />
              <HorizontalBar Data={powerQuality ? powerQuality : []} />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LoadPeak;
