import { notification } from "antd";
import { privateAPI, attachToken } from "../../API";
import { GET_ANALYSIS_DATA } from "../types/generalTypes";

export const getClientMeasNodes = () => {
  return async (dispatch) => {
    try {
      attachToken();
      const res = await privateAPI.get(`/client/getMeasNodes`);

      //   if (res) {
      //     dispatch({
      //       type: GET_ANALYSIS_DATA,
      //       payload: res.data,
      //     });
      return res.data;
      //   }
    } catch (err) {
      console.log("err", err);
      // swal("", err?.response?.data?.message || "Server Error", "error");
    }
  };
};

export const getChannelValues = (payload) => {
  return async (dispatch) => {
    try {
      // console.log("dd ",payload)
      attachToken();
      const res = await privateAPI.post(`/client/getChannelValues`, payload);
      // console.log("getChannelValues ", res) ;
      return res.data;
      //   }
    } catch (err) {
      console.log("err", err);
      // swal("", err?.response?.data?.message || "Server Error", "error");
    }
  };
};
