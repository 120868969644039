// import React, { useState, useEffect } from "react";
// import ReactDOM from "react-dom";
// import { Column } from "@ant-design/plots";

// const Co2Consumption = () => {
//   const data = [
//     {
//       name: "2022",
//       month: "Jan.",
//       value: 18.9,
//     },
//     {
//       name: "2022",
//       month: "Feb.",
//       value: 28.8,
//     },
//     {
//       name: "2022",
//       month: "Mar.",
//       value: 39.3,
//     },
//     {
//       name: "2022",
//       month: "Apr.",
//       value: 81.4,
//     },
//     {
//       name: "2022",
//       month: "May",
//       value: 47,
//     },
//     {
//       name: "2022",
//       month: "Jun.",
//       value: 20.3,
//     },
//     {
//       name: "2022",
//       month: "Jul.",
//       value: 24,
//     },
//     {
//       name: "2022",
//       month: "Aug.",
//       value: 35.6,
//     },
//     {
//       name: "2022",
//       month: "Sep.",
//       value: 47,
//     },
//     {
//       name: "2022",
//       month: "Oct.",
//       value: 20.3,
//     },
//     {
//       name: "2022",
//       month: "Nov.",
//       value: 24,
//     },
//     {
//       name: "2022",
//       month: "Dec.",
//       value: 35.6,
//     },
//     {
//       name: "2023",
//       month: "Jan.",
//       value: 12.4,
//     },
//     {
//       name: "2023",
//       month: "Feb.",
//       value: 23.2,
//     },
//     {
//       name: "2023",
//       month: "Mar.",
//       value: 34.5,
//     },
//     {
//       name: "2023",
//       month: "Apr.",
//       value: 99.7,
//     },
//     {
//       name: "2023",
//       month: "May",
//       value: 52.6,
//     },
//     {
//       name: "2023",
//       month: "Jun.",
//       value: 35.5,
//     },
//     {
//       name: "2023",
//       month: "Jul.",
//       value: 37.4,
//     },
//     {
//       name: "2023",
//       month: "Aug.",
//       value: 42.4,
//     },
//     {
//       name: "2023",
//       month: "Sep.",
//       value: 42.4,
//     },
//     {
//       name: "2023",
//       month: "Oct.",
//       value: 42.4,
//     },
//     {
//       name: "2023",
//       month: "Nov.",
//       value: 42.4,
//     },
//     {
//       name: "2023",
//       month: "Dec.",
//       value: 42.4,
//     },
//   ];
//   const config = {
//     data,
//     isGroup: true,
//     xField: "month",
//     yField: "value",
//     seriesField: "name",
//     legend: false,
//     /** 设置颜色 */
//     color: ["#DD243B", "#37A1DB"],

//     /** 设置间距 */
//     // marginRatio: 0.1,
//     label: {
//       // 可手动配置 label 数据标签位置
//       position: "middle",
//       // 'top', 'middle', 'bottom'
//       // 可配置附加的布局方法
//       layout: [
//         // 柱形图数据标签位置自动调整
//         {
//           type: "interval-adjust-position",
//         }, // 数据标签防遮挡
//         {
//           type: "interval-hide-overlap",
//         }, // 数据标签文颜色自动调整
//         {
//           type: "adjust-color",
//         },
//       ],
//     },
//   };
//   return <Column {...config} />;
// };

// export default Co2Consumption;

import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Column } from "@ant-design/plots";

const Co2Consumption = () => {
  const data = [
    {
      order_amt: 8,
      year: 2022,
      month: "Jan",
      type: "Self-2022",
    },
    {
      order_amt: 10,
      year: 2022,
      month: "Feb",
      type: "Self-2022",
    },
    {
      order_amt: 20,
      year: 2022,
      month: "March",
      type: "Self-2022",
    },
    {
      order_amt: 13,
      year: 2022,
      month: "Apr",
      type: "Self-2022",
    },
    {
      order_amt: 21,
      year: 2022,
      month: "May",
      type: "Self-2022",
    },
    {
      order_amt: 21,
      year: 2022,
      month: "Jun",
      type: "Self-2022",
    },
    {
      order_amt: 13,
      year: 2022,
      month: "Jul",
      type: "Self-2022",
    },
    {
      order_amt: 7,
      year: 2022,
      month: "Aug",
      type: "Self-2022",
    },
    {
      order_amt: 5,
      year: 2022,
      month: "Sep",
      type: "Self-2022",
    },
    {
      order_amt: 14,
      year: 2022,
      month: "Oct",
      type: "Self-2022",
    },
    {
      order_amt: 23,
      year: 2022,
      month: "Nov",
      type: "Self-2022",
    },
    {
      order_amt: 23,
      year: 2022,
      month: "Dec",
      type: "Self-2022",
    },
    {
      order_amt: 12,
      year: 2022,
      month: "Jan",
      type: "Grid-2022",
    },
    {
      order_amt: 14,
      year: 2022,
      month: "Feb",
      type: "Grid-2022",
    },
    {
      order_amt: 20,
      year: 2022,
      month: "March",
      type: "Grid-2022",
    },
    {
      order_amt: 18,
      year: 2022,
      month: "Apr",
      type: "Grid-2022",
    },
    {
      order_amt: 20,
      year: 2022,
      month: "May",
      type: "Grid-2022",
    },
    {
      order_amt: 22,
      year: 2022,
      month: "Jun",
      type: "Grid-2022",
    },
    {
      order_amt: 30,
      year: 2022,
      month: "Jul",
      type: "Grid-2022",
    },
    {
      order_amt: 30,
      year: 2022,
      month: "Aug",
      type: "Grid-2022",
    },
    {
      order_amt: 33,
      year: 2022,
      month: "Sep",
      type: "Grid-2022",
    },
    {
      order_amt: 34,
      year: 2022,
      month: "Oct",
      type: "Grid-2022",
    },
    {
      order_amt: 22,
      year: 2022,
      month: "Nov",
      type: "Grid-2022",
    },
    {
      order_amt: 20,
      year: 2022,
      month: "Dec",
      type: "Grid-2022",
    },
    {
      order_amt: 33,
      year: 2023,
      month: "Jan",
      type: "Self-2023",
    },
    {
      order_amt: 4,
      year: 2023,
      month: "Feb",
      type: "Self-2023",
    },
    {
      order_amt: 23,
      year: 2023,
      month: "March",
      type: "Self-2023",
    },
    {
      order_amt: 20.9,
      year: 2023,
      month: "Apr",
      type: "Self-2023",
    },
    {
      order_amt: 5.9,
      year: 2023,
      month: "May",
      type: "Self-2023",
    },
    {
      order_amt: 5.9,
      year: 2023,
      month: "Jun",
      type: "Self-2023",
    },
    {
      order_amt: 5.9,
      year: 2023,
      month: "Jul",
      type: "Self-2023",
    },
    {
      order_amt: 5.9,
      year: 2023,
      month: "Aug",
      type: "Self-2023",
    },
    {
      order_amt: 5.9,
      year: 2023,
      month: "Sep",
      type: "Self-2023",
    },
    {
      order_amt: 5.9,
      year: 2023,
      month: "Oct",
      type: "Self-2023",
    },
    {
      order_amt: 5.9,
      year: 2023,
      month: "Nov",
      type: "Self-2023",
    },
    {
      order_amt: 16.9,
      year: 2023,
      month: "Dec",
      type: "Self-2023",
    },
    {
      order_amt: 20,
      year: 2023,
      month: "Jan",
      type: "Grid-2023",
    },
    {
      order_amt: 33,
      year: 2023,
      month: "Feb",
      type: "Grid-2023",
    },
    {
      order_amt: 14,
      year: 2023,
      month: "March",
      type: "Grid-2023",
    },
    {
      order_amt: 12,
      year: 2023,
      month: "Apr",
      type: "Grid-2023",
    },
    {
      order_amt: 14.0,
      year: 2023,
      month: "May",
      type: "Grid-2023",
    },
    {
      order_amt: 11.9,
      year: 2023,
      month: "Jun",
      type: "Grid-2023",
    },
    {
      order_amt: 19.9,
      year: 2023,
      month: "Jul",
      type: "Grid-2023",
    },
    {
      order_amt: 19.9,
      year: 2023,
      month: "Aug",
      type: "Grid-2023",
    },
    {
      order_amt: 19.9,
      year: 2023,
      month: "Sep",
      type: "Grid-2023",
    },
    {
      order_amt: 19.9,
      year: 2023,
      month: "Oct",
      type: "Grid-2023",
    },
    {
      order_amt: 13.9,
      year: 2023,
      month: "Nov",
      type: "Grid-2023",
    },
    {
      order_amt: 9.9,
      year: 2023,
      month: "Dec",
      type: "Grid-2023",
    },
  ];

  const config = {
    data,
    xField: "month",
    yField: "order_amt",
    isGroup: true,
    isStack: true,
    seriesField: "type",
    groupField: "year",
    legend: false,
    color: ["#75232D", "#DD243B", "#025699", "#37A1DB"],
    label: {
      formatter: (datum) => {
        // console.log("dsaf ", datum);
        // return datum?.type.split("-")[0];
        return datum?.type.split("-")[0] === "Grid"
          ? datum?.type.split("-")[1] + " " + datum?.type.split("-")[0]
          : datum?.type.split("-")[0];
      },
      position: "middle",
      rotate: 80.1,
      // style:{
      //   color:"white"
      // },
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        // {
        // type: "adjust-color",
        // },
      ],
    },
    tooltip: {
      domStyles: {
        "g2-tooltip": {
          background: "transparent",
          minWidth: "250px",
          maxWidth: "250px",
          borderRadius: 0,
          padding: "0",
        },
      },
      customContent: (title, data) => {
        if (data) {
          return (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  color: "rgb(89, 89, 89)",
                  backgroundColor: "white",
                  // backgroundColor: "#131523",
                  fontFamily: "Poppins",
                  borderRadius: "4px",
                  padding: "0 20px",
                  fontSize: "10px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  filter: "drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5))",
                  position: "relative",
                  minHeight: "auto",
                  minWidth: "auto",
                }}
              >
                <div className="tooltip-bottom" />
                {/* <div className="tooltip-polygon" /> */}

                {data?.map((itm) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "5px 0",
                      }}
                    >
                      <p
                        style={{
                          background: itm?.color,
                          width: 12,
                          height: 12,
                          borderRadius: "50%",
                          marginBottom: 0,
                          marginRight: 10,
                        }}
                      />
                      <p
                        style={{
                          marginBottom: 0,
                          width: "100px",
                          // width: "35%",
                          fontSize: "15px",

                          fontWeight: "400",
                          color: "black",
                        }}
                      >
                        {itm?.name?.split("-")[0] +
                          " " +
                          itm?.name?.split("-")[1] +
                          ":"}
                      </p>
                      <p
                        style={{
                          color: "black",
                          fontSize: "15px",
                          marginBottom: 0,
                          fontWeight: "500",
                          width: "100px",
                        }}
                      >
                        {itm?.value?.toLocaleString("de-DE")} {" kg"}
                      </p>
                    </div>
                  </>
                ))}
              </div>
            </>
          );
        }
      },
    },
  };

  return <Column {...config} />;
};

// ReactDOM.render(<DemoColumn />, document.getElementById('container'));
export default Co2Consumption;
