import { useState } from "react";
import ElectricConsumptionModal from "../modal/ElectricConsumptionModal";
import ElectricCard from "./ElectricCard";
import { setDisplay } from "../../redux";
import { useDispatch } from "react-redux";

const ElectricCardMain = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const [trigger, settrigger] = useState(false);

  const dispatch = useDispatch();
  return (
    <>
      <ElectricCard
        setIsModalOpen={setIsModalOpen}
        trigger={trigger}
        settrigger={settrigger}
        setSelectedName={setSelectedName}
      />
      <ElectricConsumptionModal
        name={selectedName?.name}
        code={selectedName?.code}
        total={selectedName?.total}
        perc={selectedName?.perc}
        isModalOpen={isModalOpen}
        handleOk={() => {
          setIsModalOpen(false);
          dispatch(setDisplay());
        }}
        trigger={trigger}
        handleCancel={() => {
          setIsModalOpen(false);
          dispatch(setDisplay());
        }}
      />
    </>
  );
};

export default ElectricCardMain;
