import { useEffect } from "react";
import Routes from "./Routes";
import "antd/dist/antd.less";
import { switchTheme } from "./defaultTheme";
import "./Styles/styles.css";
import { useDispatch } from "react-redux";
import "moment-timezone";
import { notification } from "antd";
import { getClient } from "./redux";
import { useTranslation } from "react-i18next";

function App() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!navigator.onLine) {
      notification.error({
        message: "No or Slow Internet Connection",
        duration: 5,
      });
    }
  }, [navigator.onLine]);

  const getData = async () => {
    const data = await dispatch(getClient());
    i18n.changeLanguage(data.language);
  };

  useEffect(() => {
    if (localStorage.hasOwnProperty("token")) getData();
  }, []);

  useEffect(() => {
    const themeConfig = JSON.parse(localStorage.getItem("themeConfig"));
    if (themeConfig) {
      switchTheme(themeConfig.mode);
    } else {
      switchTheme();
    }
  }, []);

  return <Routes />;
}

export default App;
