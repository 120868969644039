import { Layout, Input, Badge, Avatar } from "antd";
import { FiSearch } from "react-icons/fi";
import { BellOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import MainMenu from "./MainMenu";
import logo from "../Assets/logo2.png";
import { useDispatch } from "react-redux";
import { SET_DISPLAY_ANNOTATION } from "../redux/types/generalTypes";

const VerticalLayout = ({ children, active }) => {
  const { Header, Sider, Content } = Layout;
  const history = useHistory();
  const displayMask = useSelector((state) => state.userReducer.displayMask);
  const dispatch = useDispatch();
  return (
    <div className="v-layout">
      <Layout hasSider>
        <Sider
          width={250}
          style={{
            maxHeight: "100vh",
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
            height: "auto",
            borderRight: "1px solid var(--secondary)",
            zIndex: 7,
          }}
          onMouseOver={(e) => {
            dispatch({
              type: SET_DISPLAY_ANNOTATION,
              payload: false,
            });
          }}
        >
          <div className="logo">
            <img src={logo} width="50%" />
          </div>
          <div className="side-bar-search">
            <Input placeholder="Search..." prefix={<FiSearch />} />
          </div>
          <MainMenu active={active} />
        </Sider>
        <Layout className="site-layout" style={{ marginLeft: 250 }}>
          <Header style={{ position: "fixed" }} className="layout-header">
            <div className="search-bar">
              <Input
                placeholder="Search..."
                style={{ height: "42px" }}
                prefix={<FiSearch />}
              />
            </div>
            <div className="header-right">
              <Badge count={4}>
                <Avatar
                  icon={<BellOutlined />}
                  style={{ color: "var(--text)", background: "transparent" }}
                />
              </Badge>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  className="name"
                  onClick={() => {
                    history.push("/settings");
                  }}
                >
                  {localStorage.getItem("name")
                    ? localStorage.getItem("name")
                    : ""}
                </span>
              </div>
            </div>
          </Header>
          <Content
            className="children"
            style={{
              padding: "100px 40px 0 40px",
              // margin: "60px 0 0px 0",
              // padding: 24,
              minHeight: "100vh",
            }}
          >
            {displayMask && <div className="custom-mask" />}
            {children}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default VerticalLayout;
