import { notification } from "antd";
import { privateAPI, attachToken } from "../../API";
import { GET_ANALYSIS_DATA } from "../types/generalTypes";

export const getEnergyGraphData = async (payload) => {
  try {
    attachToken();
    const res = await privateAPI.post(`/energy/get-energy-graph`, payload);

    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log("err", err);
    // swal("", err?.response?.data?.message || "Server Error", "error");
  }
};
export const getSelfConsumed = async (payload) => {
  try {
    attachToken();
    const res = await privateAPI.post(
      `/energy/get-self-consumed-energy`,
      payload
    );

    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log("err", err);
    // swal("", err?.response?.data?.message || "Server Error", "error");
  }
};

export const getAutonomyGrade = async (payload) => {
  try {
    attachToken();
    const res = await privateAPI.post(`/energy/get-autonomy-grade`, payload);

    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log("err", err);
    // swal("", err?.response?.data?.message || "Server Error", "error");
  }
};
export const getCo2Savings = async (payload) => {
  try {
    attachToken();
    const res = await privateAPI.post(`/energy/get-co2-savings`, payload);

    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log("err", err);
    // swal("", err?.response?.data?.message || "Server Error", "error");
  }
};

export const getEarnings = async (payload) => {
  try {
    attachToken();
    const res = await privateAPI.post(`/energy/get-earnings`, payload);

    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log("err", err);
    // swal("", err?.response?.data?.message || "Server Error", "error");
  }
};

export const getSavings = async (payload) => {
  try {
    attachToken();
    const res = await privateAPI.post(`/energy/get-savings`, payload);

    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log("err", err);
    // swal("", err?.response?.data?.message || "Server Error", "error");
  }
};
