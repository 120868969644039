import React, { useState, useRef } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";
import LiveElectricConsumptionModal from "./LiveElectricConsumptionModal";
// import { setDisplay } from "../../redux";

const ElectricConsumptionModal = ({
  name,
  code,
  total,
  perc,
  strokeColor,
  isModalOpen,
  handleOk,
  trigger,
  handleCancel,
}) => {
  // console.log("name ", name);
  // console.log("code ", code);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [trigger, settrigger] = useState(false);

  // const dispatch = useDispatch();
  // const showModal = () => {
  //   setIsModalOpen(true);
  //   // userReducer
  //   settrigger(!trigger);
  //   dispatch(setDisplay());
  // };

  // // console.log("Code ", code);
  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };
  // const handleCancel = () => {
  //   setIsModalOpen(false);
  //   dispatch(setDisplay());
  // };

  return (
    <div>
      {/* <p style={{ cursor: "pointer" }} onClick={showModal}>
        {name}
      </p> */}
      <Modal
        footer={false}
        width={1200}
        centered
        mask={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={true}
        closeIcon={<></>}
        style={{ marginLeft: "200px" }}
      >
        <LiveElectricConsumptionModal
          name={name}
          code={code}
          trigger={trigger}
        />
      </Modal>
    </div>
  );
};
export default ElectricConsumptionModal;
