import { Row, Col, Tooltip } from "antd";
import ChartjsDonut from "./charts/ChartjsDonut";
import { useSelector } from "react-redux";
import HoverCard from "./cards/HoverCard";
import React, { useEffect, useState } from "react";

const ElectricConsumptionRoundChart = ({ electricConsumption, filter }) => {
  const colors = useSelector((state) => state.userReducer.colors);
  const [trigger, settrigger] = useState(false);

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          style={{
            height: "250px",
            width: "250px",
            display: "flex",
            margin: "0 auto",
          }}
        >
          <ChartjsDonut
            Data={
              electricConsumption
                ? electricConsumption
                : {
                    labels: [],
                    data: [],
                  }
            }
          />
        </div>
        {/* <ChartjsDonut /> */}

        <p
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "fit-content",
            transform: "translate(-50%,-50%)",
            // marginLeft: "-45px",
            textAlign: "center",
          }}
        >
          <span
            style={{
              fontWeight: "600",
              fontSize: "25px",
            }}
          >
            {electricConsumption?.total?.toLocaleString("de-DE")}
          </span>
          <br />
          <span style={{ color: "var(--secondaryText)" }}>kWh</span>
        </p>
      </div>
      <Row
        gutter={[0, 5]}
        justify="end"
        style={{
          marginTop: "1rem",
          width: "100%",
          display: "flex",
          margin: "1rem auto 0 auto",
        }}
      >
        {
          // [
          //   {
          //     color: "#37A1DB",
          //     name: "Spa",
          //     percent: "52%",
          //   },
          //   {
          //     color: "#46C782",
          //     name: "E-Mobility",
          //     percent: "21%",
          //   },
          //   {
          //     color: "#DBD621",
          //     name: "Restaurant",
          //     percent: "18%",
          //   },
          //   {
          //     color: "#E58448",
          //     name: "Laundry",
          //     percent: "9%",
          //   },
          // ]
          electricConsumption?.labels?.map((label, index) => (
            <Col xs={24} sm={24} md={10} key={index}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "80%",
                  // justifyContent: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ width: "auto" }}>
                    <p
                      style={{
                        background: colors?.[index],
                        width: "10px",
                        height: "10px",
                        borderRadius: "2px",
                        marginBottom: 0,
                        marginRight: "10px",
                      }}
                    ></p>
                  </div>
                  &nbsp;
                  <Tooltip
                    placement="left"
                    title={
                      <HoverCard
                        label={label}
                        trigger={trigger}
                        total={electricConsumption?.total}
                        perc={Number(
                          (
                            (electricConsumption?.data[index] /
                              electricConsumption?.total) *
                            100
                          ).toFixed()
                        )}
                        filter={filter}
                        strokeColor={colors?.[index]}
                      />
                    }
                    color="transparent"
                    onOpenChange={() => settrigger(!trigger)}
                  >
                    <p
                      style={{
                        marginBottom: 0,
                        marginRight: 10,
                        fontSize: 12,
                        width: "65%",
                        cursor: "pointer",
                      }}
                    >
                      {label}
                    </p>
                  </Tooltip>
                </div>

                <p
                  style={{
                    marginBottom: 0,
                    fontSize: 12,
                    fontWeight: "bold",
                    width: "20%",
                  }}
                >
                  {(
                    (electricConsumption?.data[index] /
                      electricConsumption?.total) *
                    100
                  ).toFixed()}
                  %
                </p>
              </div>
            </Col>
          ))
        }
      </Row>
    </>
  );
};

export default ElectricConsumptionRoundChart;
