import React, { useState } from "react";
import { Typography, Modal } from "antd";
import PvProductionChart from "../charts/PvProductionChart";

const App = ({
  pvProduction,
  data,
  showModal,
  isModalOpen,
  handleOk,
  handleCancel,
  pvFilter,
}) => {
  // console.log("pvFilter ", pvFilter);
  // console.log("pvProduction ", data);
  return (
    <>
      <Typography.Title onClick={showModal} className="title" level={5}>
        {pvProduction ? "PV Production" : "Power Quality"}
      </Typography.Title>
      {/* <Button type="primary" onClick={showModal}>
        Open Modal
      </Button> */}
      {/* <div className="pvModal"> */}
      <Modal
        footer={false}
        width="80%"
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={true}
        closeIcon={<></>}
        mask={false}
        style={{margin:"100px 0 0 200px"}}
      >
        <PvProductionChart pvdata={data} val={pvFilter?.value} />
      </Modal>
      {/* </div> */}
    </>
  );
};
export default App;
