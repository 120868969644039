import React, { useEffect, useState, useRef } from "react";
import { Typography, Progress } from "antd";
import { useSelector } from "react-redux";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { getElectricConsumption } from "../../redux";
import ElectricConsumptionModal from "../modal/ElectricConsumptionModal";
import { setDisplay } from "../../redux";
import { useDispatch } from "react-redux";
import { useMemo } from "react";

const ElectricCard = ({
  setIsModalOpen,
  setSelectedName,
  settrigger,
  trigger,
}) => {
  const electricRef = useRef(null);

  const [electricConsumption, setElectricConsumption] = useState(null);
  // const [selectedName, setSelectedName] = useState(null);
  // const memoizedSelectedName = useMemo(() => selectedName, [selectedName]);
  const colors = useSelector((state) => state.userReducer.colors);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [trigger, settrigger] = useState(false);
  const dispatch = useDispatch();
  const showModal = (data, index) => {
    setIsModalOpen(true);
    // userReducer
    setSelectedName({
      ...data,
      total: Number(electricConsumption?.TotalConsumption?.toFixed(2)),
      perc: electricConsumption?.dataChannels[index].percentage?.toFixed(),
    });
    settrigger(!trigger);
    dispatch(setDisplay());
  };
  // console.log("memoizedSelectedName ", memoizedSelectedName);

  // console.log("Code ", code);
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    dispatch(setDisplay());
  };
  // console.log("colors", colors);
  const getData = async () => {
    const result = await getElectricConsumption();
    setElectricConsumption(result);
  };

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    const timeoutID = setInterval(() => {
      getData();
    }, 5000);

    return () => {
      // console.log("from electric consuption timeoutID");
      clearInterval(timeoutID);
    };
  }, []);

  return (
    <div className="electric-consumption-outer" ref={electricRef}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // marginBottom: ".5rem",
        }}
      >
        <Typography.Title
          className="title"
          level={5}
          style={{ fontWeight: "600" }}
        >
          Live Electric Consumption
        </Typography.Title>
      </div>
      <div className="electric-consumption">
        <div className="electric-consumption-left">
          <Typography.Title className="sub-title" level={4}>
            {electricConsumption?.TotalConsumption
              ? Number(
                  electricConsumption?.TotalConsumption?.toFixed(2)
                ).toLocaleString("de-DE")
              : "0"}{" "}
            kW
          </Typography.Title>
          <div className="percentage-wrapper">
            <span className="percentage" style={{ color: "var(--green)" }}>
              {" "}
              {electricConsumption?.Last_Hour_Comparison
                ? electricConsumption?.Last_Hour_Comparison > 0
                  ? electricConsumption?.Last_Hour_Comparison?.toLocaleString(
                      "de-DE"
                    )
                  : String(
                      electricConsumption?.Last_Hour_Comparison?.toLocaleString(
                        "de-DE"
                      )
                    )?.substr(1)
                : "0"}
              %&nbsp;
              {electricConsumption?.Last_Hour_Comparison > 0 ? (
                <BsArrowUp style={{ strokeWidth: 2 }} />
              ) : (
                <BsArrowDown style={{ strokeWidth: 2 }} />
              )}
            </span>
            &nbsp;
            <span className="text">than last hour</span>
          </div>
        </div>
        <div className="electric-consumption-right">
          {electricConsumption?.dataChannels?.map((data, index) => (
            <div className="bar-chart-warpper" key={index}>
              <p
                style={{ cursor: "pointer", marginRight: "10px" }}
                onClick={() => {
                  showModal(data, index);
                }}
              >
                {data?.name}
              </p>

              <Progress
                strokeLinecap="square"
                strokeColor={colors?.[index]}
                percent={electricConsumption?.dataChannels[
                  index
                ].percentage?.toFixed()}
                strokeWidth={6}
              />
            </div>
          ))}

          {/* <div className="percentage-wrapper-middle"></div> */}
        </div>
      </div>
    </div>
  );
};

export default ElectricCard;
