import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Line } from "@ant-design/plots";
import moment from "moment";
import { ClockCircleFilled } from "@ant-design/icons";
const CompareChart = ({ channel, time, unit }) => {
  const [data, setData] = useState([]);
  const [color, setColor] = useState([]);
  const [chartClick, setChartClick] = useState([]);
  const [diffInt, setDiffInt] = useState(null);
  const [clientx, setClientx] = useState(0);
  const [clienty, setClienty] = useState(0);
  const [displaTooltip, setDisplayTooltip] = useState(false);

  // console.log('clientx', clientx)
  // console.log('clienty', clienty)
  useEffect(() => {
    if (chartClick.length == 2) {
      chartClick?.[0]?.map((item) => {});
      setChartClick([]);
      console.log("chart click ", chartClick);
      let temparr = [];
      chartClick?.[0]?.map((cc, i) => {
        let obj = {
          name: cc?.name,
          interval: cc?.date + " - " + chartClick?.[1]?.[i]?.date,
          value: Math.abs(cc?.value - chartClick?.[1]?.[i]?.value),
        };
        temparr.push(obj);
      });
      console.log(temparr);
    }
  }, [chartClick]);
  useEffect(() => {
    // console.log("use effect");
    setData([]);
    setColor([]);
    channel?.length > 0 &&
      channel?.map((item, index) => {
        setColor((color) => [...color, item?.color]);

        item?.data?.length > 0 &&
          item?.data?.map((kw) => {
            setData((data) => [
              ...data,
              {
                name: item?.value?.name,
                date:
                  time == "5m" || time == "15m"
                    ? moment(kw?._time)
                        .utc()
                        // .tz("Europe/Rome")
                        .format("YYYY-MM-DD(HH:mm)")
                    : time == "hourly"
                    ? moment(kw?._time)
                        .utc()
                        // .tz("Europe/Rome")
                        .format("YYYY-MM-DD(HH:00)")
                    : time == "daily"
                    ? moment(kw?._time)
                        .utc()
                        // .tz("Europe/Rome")
                        .format("YYYY-MM-DD")
                    : time == "weekly"
                    ? moment(kw?._time)
                        .utc()
                        // .tz("Europe/Rome")
                        .format("YYYY-MM-DD")
                    : time == "monthly"
                    ? moment(kw?._time)
                        .utc()
                        // .tz("Europe/Rome")
                        .format("DD-MMM-YYYY")
                    : time == "yearly"
                    ? moment(kw?._time)
                        .utc()
                        // .tz("Europe/Rome")
                        .format("YYYY")
                    : null,
                value: kw?._value,
                interval: kw?.interval,
              },
            ]);
          });
      });
  }, [channel, time]);
  // console.log("color ", color);
  // useEffect(() => {
  //   console.log("data ", data);
  // }, [data]);
  const config = {
    data: data,
    smooth: true,
    // height: 500,
    className: "comp_chart",
    xField: "date",
    yField: "value",
    seriesField: "name",
    // color: ["black", "white", "red", "blue", "grey"],
    color: color,
    slider: {
      start: 0,
      end: 1,
    },
    xaXis: {
      tickCount: 13,
    },
    tooltip: {
      customContent: (title, data) => {
        return null;
      },
    },
    tooltip: {
      // reversed : true,
      // follow : true,
      domStyles: {
        "g2-tooltip": {
          background: "transparent",
          minWidth: "250px",
          maxWidth: "250px",
          borderRadius: 0,
          padding: "0",
        },
      },
      customContent: (title, data) => {
        if (data) {
          return (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  // height: 80,
                  // width: 250,
                  color: "rgb(89, 89, 89)",
                  backgroundColor: "white",
                  fontFamily: "Poppins",
                  borderRadius: "4px",
                  padding: "0 20px",
                  fontSize: "10px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  filter: "drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5))",
                  position: "relative",
                  minHeight: "auto",
                  minWidth: "300px",
                }}
              >
                <div className="tooltip-polygon" />

                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "13px",
                    color: "black",
                  }}
                >
                  {time == "hourly" || time == "15m" || time == "5m" ? (
                    <>
                      {data?.[0]?.data?.interval?.date + " "}
                      <ClockCircleFilled
                        style={{
                          marginLeft: "10px",
                          marginRight: "3px",
                        }}
                      />
                      {data?.[0]?.data?.interval?.time}
                    </>
                  ) : (
                    data?.[0]?.data?.interval
                  )}
                </p>
                {data?.map((itm) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "2px 0",
                      }}
                    >
                      <p
                        style={{
                          background: itm?.color,
                          width: 10,
                          height: 10,
                          borderRadius: "50%",
                          marginBottom: 0,
                          marginRight: 10,
                        }}
                      />
                      <p
                        style={{
                          marginBottom: 0,
                          width: "120px",
                          // width: "35%",
                          fontSize: "12px",

                          fontWeight: "500",
                          color: "black",
                        }}
                      >
                        {itm?.name}
                      </p>
                      <p
                        style={{
                          // color: "#7E84A3",
                          color: "black",
                          fontSize: "11px",
                          marginBottom: 0,
                          fontWeight: "500",
                          // width: "110px",
                        }}
                      >
                        {itm?.value?.toLocaleString("de-DE")}{" "}
                        {unit ? " " + unit : " kW"}
                      </p>
                    </div>
                  </>
                ))}
              </div>
            </>
          );
        }
      },
    },
    yAxis: {
      label: {
        formatter: (v) =>
          `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    legend: false,
    // yAxis: {
    //   tickCount: 10,
    // },
  };
  return (
    <>
      {displaTooltip && (
        <div
          style={{
            filter: "drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5))",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flex: 1,
            height: 65,
            width: 240,
            boxShadow: "rgb(174 174 174) 0px 0px 10px",
            transition:
              "left 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s, top 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s",
            color: "rgb(89, 89, 89)",
            backgroundColor: "red",
            fontFamily: "Poppins",
            // color: "white",
            //   // left: clientX,
            left: clientx - 100,
            top: clienty - 100,
            position: "fixed",
            borderRadius: "4px",
            padding: "0 20px",
            fontSize: "10px",
            paddingTop: "5px",
            // opacity: selectedItem.name === "" ? 0 : 1,
            zIndex: 9,
          }}
        >
          <p>tooltip</p>
        </div>
      )}
      <Line
        {...config}
        autoFit
        // tooltip={{
        // }}
        onReady={(plot) => {
          plot.on("plot:click", (event) => {
            let a = event.view.ele.textContent;
            let date = a.slice(0, 10);
            let time = a.slice(11, 24);
            let filteredData = event?.view?.filteredData;
            // console.log(a);
            // console.log(date);
            // console.log(time);
            // console.log(filteredData);

            let findData = filteredData?.filter((obj) => {
              return (
                obj?.interval?.date === date && obj?.interval?.time === time
              );
            });
            // console.log("findData ", findData);
            setChartClick((cc) => [...cc, findData]);
          });
          // plot.on("mouseover", (event) => {
          //  console.log('event', event)
          //  setClientx(event.clientX)
          //  setClienty(event.clientY)
          // });

          // plot.on("tooltip:show", (args) => {
          //   // console.log("show", args);
          //   // setClientx(args.clientX);
          //   // setClienty(args.clientY);
          //   setDisplayTooltip(true);
          // });
          // plot.on("tooltip:hide", (...args) => {
          //   setDisplayTooltip(false)
          // });
          // plot.on("tooltip:change", (...args) =>
          // {
          //   setDisplayTooltip(true)
          // });
        }}
      />
    </>
  );
};
export default CompareChart;
