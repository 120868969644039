import React, { useEffect, useState } from "react";
import { Row, Col, Progress } from "antd";
import ChartjsDonut from "./charts/ChartjsDonut";
import { getElectricConsumption } from "../redux";
import { useSelector } from "react-redux";

const ElectricConsumptionRoundChart = ({}) => {
  const colors = useSelector((state) => state.userReducer.colors);

  const [electricConsumption, setElectricConsumption] = useState(null);
  const [roundChart, setRoundChart] = useState(null);
  // const [Filter, setFilter] = useState({
  //   value: "Last 12 Months",
  //   key: "12m",
  // });
  // console.log(electricConsumption?.dataChannels);
  const getData = async () => {
    const result = await getElectricConsumption();
    let labels = [];
    let Data = [];
    result?.dataChannels?.map((data) => {
      labels.push(data.name);
      Data.push(data.percentage);
    });
    setRoundChart({
      labels,
      Data,
    });
    setElectricConsumption(result);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const timeoutID = setInterval(() => {
      getData();
    }, 5000);

    return () => {
      console.log("electric consuption round timeoutID");
      clearInterval(timeoutID);
    };
  }, []);

  // console.log("electricConsumption live", electricConsumption);

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          style={{
            height: "250px",
            width: "250px",
            display: "flex",
            margin: "0 auto",
          }}
        >
          <ChartjsDonut
            Data={
              electricConsumption
                ? {
                    labels: roundChart?.labels,
                    data: roundChart?.Data,
                  }
                : {
                    labels: [],
                    data: [],
                  }
            }
          />
        </div>
        {/* <ChartjsDonut /> */}

        <p
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            // marginLeft: "-45px",
            textAlign: "center",
            boxShadow: "0px 2px 13px rgba(0, 0, 0, 0.2)",
            height: "170px",
            width: "170px",
            // height: "120px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <span
            style={{
              fontWeight: "600",
              fontSize: "2rem",
            }}
          >
            {Number(
              electricConsumption?.TotalConsumption?.toFixed(2)
            ).toLocaleString("de-DE")}
          </span>
          <span
            style={{
              color: "black",
              fontSize: "1.4rem",
              opacity: 0.5,
              fontWeight: 500,
            }}
          >
            kW
          </span>
        </p>
      </div>
      <Row
        gutter={[0, 5]}
        justify="end"
        style={{
          marginTop: "1rem",
          width: "100%",
          display: "flex",
          margin: "1rem auto 0 auto",
        }}
      >
        {
          // [
          //   {
          //     color: "#37A1DB",
          //     name: "Spa",
          //     percent: "52%",
          //   },
          //   {
          //     color: "#46C782",
          //     name: "E-Mobility",
          //     percent: "21%",
          //   },
          //   {
          //     color: "#DBD621",
          //     name: "Restaurant",
          //     percent: "18%",
          //   },
          //   {
          //     color: "#E58448",
          //     name: "Laundry",
          //     percent: "9%",
          //   },
          // ]
          electricConsumption?.dataChannels?.map((label, index) => (
            <Col xs={24} key={index}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  // justifyContent: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ width: "auto" }}>
                    <p
                      style={{
                        background: colors?.[index],
                        width: "10px",
                        height: "10px",
                        borderRadius: "2px",
                        marginBottom: 0,
                        marginRight: "10px",
                      }}
                    ></p>
                  </div>
                  <p
                    style={{
                      marginBottom: 0,
                      marginRight: 10,
                      fontSize: 12,
                      width: "fit-content",
                    }}
                  >
                    {label.name}
                  </p>
                </div>
                <div style={{ width: "70%" }}>
                  <Progress
                    percent={label.percentage}
                    strokeColor={colors?.[index]}
                    trailColor={"#D9D9D9"}
                  />
                </div>

                {/* <p
                  style={{
                    marginBottom: 0,
                    fontSize: 12,
                    fontWeight: "bold",
                    width: "20%",
                  }}
                >
                  {(
                    (electricConsumption?.data[index] /
                      electricConsumption?.total) *
                    100
                  ).toFixed()}
                  %
                </p> */}
              </div>
            </Col>
          ))
        }
      </Row>
    </>
  );
};

export default ElectricConsumptionRoundChart;
