import { useState } from "react";
import { Button, Form, Input, Select, notification } from "antd";
import { useHistory, Redirect, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { countryCodes } from "../CountryCodes";
import { clientSignup } from "../redux";
import logo from "../Assets/logo2.png";

const { Option } = Select;

const Login = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("");

  const Login = async (values) => {
    if (values.password === values.Cpassword) {
      setLoading(true);
      await clientSignup({ ...values }, history);
      // await dispatch(clientSignUp({ ...values, countryCode }, history));
      setLoading(false);
    } else {
      notification.error({
        message: "Password and Confirm Password Doesnot Matched.",
        duration: 3,
      });
    }
  };

  if (localStorage.hasOwnProperty("token")) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className="temp-login-main signup-main">
        <div className="login-main">
          <img src={logo} />
          <p>Fill below fields to cretae your account</p>

          <Form layout="vertical" onFinish={Login}>
            <Form.Item name={"userName"} label={"Name"}>
              <Input />
            </Form.Item>
            <Form.Item name={"email"} label={"Email Address"}>
              <Input />
            </Form.Item>
            <Form.Item name={"password"} label={"Password"}>
              <Input type="password" />
            </Form.Item>
            <Form.Item name={"Cpassword"} label={"Confirm Password"}>
              <Input type="password" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" loading={loading} htmlType="submit">
                Submit
              </Button>
            </Form.Item>
            <div className="signup">
              Already have account?{" "}
              <Link to="/login">
                <span>Login</span>
              </Link>
            </div>
          </Form>
        </div>
      </div>
    );
  }
};

export default Login;
