import { Liquid } from "@ant-design/plots";

const DemoLiquid = ({ percentage, status, color, size, content, time }) => {
  const config = {
    height: size ? size : 150,
    width: size ? size : 150,
    percent: percentage,
    statistic: {
      style: {
        fontSize: 20,
        color: "white",
      },
    },
    color: status === "Completed" ? "#D5D7E3" : "#46C782",
    // color: status === "Completed" ? "#46C782" : color ? color : "#37A1DB",
    outline: {
      border: 2,
      distance: 4,
      style: {
        stroke: status === "Completed" ? "#D5D7E3" : "#46C782",
      },
    },
    wave: {
      length: 128,
    },

    statistic: content
      ? {
          title: {
            offsetY: 0,
            style: {
              fontSize: "1rem",
              fontWeight: 600,
              color: "#000000",
            },
            formatter: () => (percentage * 100).toFixed() + "%",
          },
          content: {
            offsetY: -15,
            style: {
              fontSize: "0.6rem",
              fontWeight: 500,
              lineHeight: "44px",
              color: "#000000",
            },
            formatter: () => time,
          },
        }
      : {},
  };
  return <Liquid {...config} className="" />;
};

export default DemoLiquid;
