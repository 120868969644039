import Layout from "../Layout/MainLayout";
import {
  Row,
  Col,
  Typography,
  DatePicker,
  Button,
  Input,
  Dropdown,
  TreeSelect,
  Empty,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import CompareChart from "../Components/charts/CompareChart";
import { BsSearch, BsFillPlusSquareFill } from "react-icons/bs";
import { DownOutlined } from "@ant-design/icons";
import { getClientMeasNodes, getChannelValues } from "../redux";
import { useDispatch } from "react-redux";
import moment from "moment";
import { subtract } from "lodash";
import Loader from "../Components/Loader";
import LiveElectricConsumptionModal from "../Components/modal/LiveElectricConsumptionModal";
import { SearchOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

const Compare = () => {
  const { RangePicker } = DatePicker;
  const handleChange = (value) => {
    // console.log(`selected ${value}`);
  };
  // const [value, setValue] = useState(1);
  const [btnSelect, setBtnSelect] = useState("hourly");

  const [value, setValue] = useState(undefined);
  const [channelss, setChannel] = useState([]);
  const [tempChannel, setTempChannel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [childrenColors, setChildrenColors] = useState([]);
  const [dtl, setDtl] = useState([]);

  const location = useLocation();
  const [end, setEnd] = useState(null);
  // moment(moment()).format("YYYY-MM-DD") + "T23:58:58+00:00"
  const [start, setStart] = useState(null);

  const onSearchBtnClick = async () => {
    setChannel([]);
    let tempValue = [];
    // console.log("value ", value);
    // value?.length &&
    value?.map(async (val) => {
      setLoading(true);
      let tmp = JSON.parse(val.split("+")[0]);
      // console.log("val ", val);

      tempValue.push(val);
      let data = await dispatch(
        getChannelValues({
          range: btnSelect,
          type: tmp?.Type,
          code: tmp?.code,
          start: start,
          end: end,
          aggregateWindow:
            btnSelect == "hourly"
              ? "1h"
              : btnSelect == "daily"
              ? "1d"
              : btnSelect == "weekly"
              ? "7d"
              : btnSelect == "monthly"
              ? "30d"
              : btnSelect == "yearly"
              ? "365d"
              : btnSelect,
        })
      );
      setChannel((channelss) => [
        ...channelss,
        { value: tmp, data, color: val.split("+")[1] },
      ]);
      setLoading(false);
    });

    localStorage.setItem(
      "chartData",
      JSON.stringify({
        channels: tempValue,
        startDate: start,
        endDate: end,
        interval: btnSelect,
      })
    );
  };

  const onChange = async (newValue) => {
    setValue(newValue);
  };
  const onStartDateChange = async (newValue) => {
    // console.log("dtte ", moment(newValue).format("YYYY-MM-DD"));
    setStart(moment(newValue).format("YYYY-MM-DD") + "T00:00:00+00:00");
    // setTempChannel([]);
    // channelss?.length > 0 &&
    //   channelss?.map(async (item) => {
    //     setLoading(true);
    //     let data = await dispatch(
    //       getChannelValues({
    //         range: btnSelect,
    //         type: item?.value?.Type,
    //         code: item?.value?.code,
    //         start: moment(newValue).format("YYYY-MM-DD") + "T00:00:00+00:00",
    //         end: end,
    //         aggregateWindow:
    //           btnSelect == "hourly"
    //             ? "1h"
    //             : btnSelect == "daily"
    //             ? "1d"
    //             : btnSelect == "weekly"
    //             ? "7d"
    //             : btnSelect == "monthly"
    //             ? "30d"
    //             : btnSelect == "yearly"
    //             ? "365d"
    //             : btnSelect,
    //       })
    //     );
    //     if (data) {
    //       setTempChannel((tempChannel) => [
    //         ...tempChannel,
    //         {
    //           value: item?.value,
    //           color: item?.color,
    //           data: data,
    //         },
    //       ]);
    //     }
    //     setLoading(false);
    //   });
    // if (channelss.length > 0) {
    //   localStorage.setItem(
    //     "chartData",
    //     JSON.stringify({
    //       channels: value,
    //       startDate: moment(newValue).format("YYYY-MM-DD") + "T00:00:00+00:00",
    //       endDate: end,
    //       interval: btnSelect,
    //     })
    //   );
    // }
  };

  const onEndDateChange = async (newValue) => {
    setEnd(moment(newValue).format("YYYY-MM-DD"));
  };
  const onRangeChange = async (val) => {
    // console.log("val ", val);
    let startDate = start;
    setBtnSelect(val);
    setTempChannel([]);
    channelss?.length > 0 &&
      channelss?.map(async (item) => {
        setLoading(true);
        let data = await dispatch(
          getChannelValues({
            range: val,
            type: item?.value?.Type,
            code: item?.value?.code,
            start: startDate,
            end: end,
            aggregateWindow:
              val == "hourly"
                ? "1h"
                : val == "daily"
                ? "1d"
                : val == "weekly"
                ? "7d"
                : val == "monthly"
                ? "30d"
                : val == "yearly"
                ? "365d"
                : val,
          })
        );
        setTempChannel((tempChannel) => [
          ...tempChannel,
          {
            value: item?.value,
            color: item?.color,
            data: data,
          },
        ]);
        setLoading(false);
      });

    if (channelss.length > 0) {
      localStorage.setItem(
        "chartData",
        JSON.stringify({
          channels: value,
          startDate: start,
          endDate: end,
          interval: val,
        })
      );
    }
  };
  const dispatch = useDispatch();
  console.log("location?.state ", location?.state);
  useEffect(() => {
    (async () => {
      setLoading(true);
      await getData().then((childrens) => {
        if (location?.state?.seeInDetail) {
          let consumpDetails = location?.state?.seeInDetail;
          if (location?.state?.start) {
            setStart(location?.state?.start);
          }
          if (location?.state?.end) {
            setEnd(moment(location?.state?.end).format("YYYY-MM-DD"));
          }
          let tempValues = [];
          setChannel([]);
          consumpDetails?.map(async (itm) => {
            let colorObj = childrens?.find((obj) => {
              return obj?.code === itm?.code;
            });
            tempValues.push(
              JSON.stringify({ code: itm?.code, name: itm?.name }) +
                "+" +
                colorObj?.color
            );
            let data = await dispatch(
              getChannelValues({
                range: btnSelect,
                // type: tmp?.Type,
                code: itm?.code,
                start: location?.state?.start ? location?.state?.start : start,
                end: location?.state?.end
                  ? moment(location?.state?.end).format("YYYY-MM-DD")
                  : end,
                aggregateWindow:
                  btnSelect == "hourly"
                    ? "1h"
                    : btnSelect == "daily"
                    ? "1d"
                    : btnSelect == "weekly"
                    ? "7d"
                    : btnSelect == "monthly"
                    ? "30d"
                    : btnSelect == "yearly"
                    ? "365d"
                    : btnSelect,
              })
            );
            setChannel((channelss) => [
              ...channelss,
              {
                value: { code: itm?.code, name: itm?.name },
                data,
                color: colorObj?.color,
              },
            ]);
          });
          setValue(tempValues);
          window.history.replaceState({}, document.title);
          localStorage.setItem(
            "chartData",
            JSON.stringify({
              channels: tempValues,
              startDate: start,
              endDate: end,
              interval: btnSelect,
            })
          );
        } else {
          let chartdata = JSON.parse(localStorage.getItem("chartData"));
          setValue(chartdata?.channels);
          setStart(chartdata?.startDate);
          setEnd(chartdata?.endDate);
          if (chartdata?.interval) {
            setBtnSelect(chartdata?.interval);
          }
          chartdata?.channels?.length > 0 &&
            chartdata?.channels?.map(async (itm) => {
              let tmp = JSON.parse(itm.split("+")[0]);
              let data = await dispatch(
                getChannelValues({
                  range: chartdata?.interval,
                  type: tmp?.Type,
                  code: tmp?.code,
                  start: chartdata?.startDate,
                  end: chartdata?.endDate,
                  aggregateWindow:
                    chartdata?.interval == "hourly"
                      ? "1h"
                      : chartdata?.interval == "daily"
                      ? "1d"
                      : chartdata?.interval == "weekly"
                      ? "7d"
                      : chartdata?.interval == "monthly"
                      ? "30d"
                      : chartdata?.interval == "yearly"
                      ? "365d"
                      : chartdata?.interval,
                })
              );
              setChannel((channelss) => [
                ...channelss,
                {
                  value: { code: tmp?.code, Type: tmp?.Type, name: tmp?.name },
                  data,
                  color: itm.split("+")[1],
                },
              ]);
            });
        }
      });
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (channelss.length !== 0) {
      channelss.sort((a, b) => a?.value?.name?.localeCompare(b?.value?.name));
      // console.log("channel ", channelss);
    }
  }, [channelss]);

  useEffect(() => {
    // console.log("tempChannel ", tempChannel);

    setChannel(tempChannel);
  }, [tempChannel]);
  const [treeData, setTreeData] = useState([]);

  const items = [
    {
      label: "1st menu item",
      key: "0",
    },
    {
      label: "2nd menu item",
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: "3rd menu item",
      key: "3",
    },
  ];

  let tempArr = [];
  let childrenArr = [];

  let colors = [
    "#FF33FF",
    "#FFFF99",
    "#00B3E6",
    "#E6B333",
    "#3366E6",
    "#999966",
    "#99FF99",
    // "#B34D4D",
    "#80B300",
    // "#809900",
    "#E6B3B3",
    "#6680B3",
    "#66991A",
    "#FF99E6",
    "#CCFF1A",
    "#FF1A66",
    "#E6331A",
    "#33FFCC",
    "#66994D",
    "#B366CC",
    "#4D8000",
    "#B33300",
    "#CC80CC",
    "#66664D",
    "#991AFF",
    "#E666FF",
    "#4DB3FF",
    "#1AB399",
    "#E666B3",
    "#33991A",
    "#CC9999",
    "#B3B31A",
    "#00E680",
    "#4D8066",
    "#809980",
    "#E6FF80",
    "#1AFF33",
    "#999933",
    "#FF3380",
    "#CCCC00",
    "#66E64D",
    "#4D80CC",
    "#9900B3",
    "#E64D66",
    "#4DB380",
    "#FF4D4D",
    "#99E6E6",
    "#6666FF",
  ];

  const getData = () => {
    return new Promise(async function (resolve, reject) {
      const res = await dispatch(getClientMeasNodes());
      tempArr.push(
        {
          value:
            JSON.stringify({
              code: "carbonIntensity",
              name: "Carbon Intensity",
            }) +
            "+" +
            "#FF6633",
          title: (
            <div className="tree-flex">
              <div className="blue-block" style={{ background: "#FF6633" }} />
              <div className="tree-parent">
                <p>Carbon Intensity</p>
              </div>
            </div>
          ),
        },
        {
          value:
            JSON.stringify({
              code: "fossilFuelPercentage",
              name: "Fossil Fuel Percentage",
            }) +
            "+" +
            "#FFB399",
          title: (
            <div className="tree-flex">
              <div className="blue-block" style={{ background: "#FFB399" }} />
              <div className="tree-parent">
                <p>Fossil Fuel Percentage</p>
              </div>
            </div>
          ),
        }
      );
      let count = 0;
      res?.length &&
        res?.map((nodes, i) => {
          let tempChildren = [];
          count++;
          nodes?.subNodes?.length &&
            nodes?.subNodes?.map((sub, index) => {
              count++;
              childrenArr.push({
                name: sub?.name,
                code: sub?.code,
                color: colors[count],
              });
              tempChildren.push({
                value:
                  JSON.stringify({ code: sub?.code, name: sub?.name }) +
                  "+" +
                  colors[count],
                title: (
                  <div
                    className="tree-flex"
                    key={
                      JSON.stringify({ code: sub?.code, name: sub?.name }) +
                      "+" +
                      colors[count]
                    }
                  >
                    <div
                      className="blue"
                      style={{ background: colors[count] }}
                    />
                    <div className="tree-parent">
                      <p>{sub?.name}</p>
                    </div>
                  </div>
                ),
              });
            });
          tempArr.push({
            value:
              JSON.stringify({ code: nodes?.code, name: nodes?.name }) +
              "+" +
              colors[count],
            title: (
              <div
                className="tree-flex"
                key={
                  JSON.stringify({ code: nodes?.code, name: nodes?.name }) +
                  "+" +
                  colors[count]
                }
              >
                <div
                  className="blue-block"
                  style={{ background: colors[count] }}
                />
                <div className="tree-parent">
                  <p>{nodes?.name}</p>
                </div>
              </div>
            ),
            children: tempChildren,
          });
        });
      setTreeData(tempArr);
      resolve(childrenArr);
    });
  };

  return (
    <Layout active="details">
      <div className="compare-container">
        <Typography.Title
          className="title"
          level={5}
          style={{ fontSize: "18px", fontWeight: 600 }}
        >
          Details
        </Typography.Title>
        <Typography.Title
          className="sub-title"
          level={4}
          style={{ fontSize: "14px", fontWeight: 400 }}
        >
          Select to compare charts
        </Typography.Title>
        <div className="search-boxes">
          <Row gutter={[10, 10]}>
            <Col xs={12} md={7}>
              <TreeSelect
                multiple
                showSearch
                // suffixIcon={<DownOutlined />}
                // showArrow
                maxTagCount={1}
                treeIcon={false}
                switcherIcon={<></>}
                style={{
                  width: "100%",
                }}
                value={value}
                dropdownStyle={{
                  maxHeight: 400,
                  overflow: "auto",
                }}
                // placeholder="Please select"
                allowClear
                treeDefaultExpandAll
                onChange={onChange}
                treeData={treeData}
              />
            </Col>
            <Col xs={12} md={8}>
              {/* <RangePicker /> */}
              <div className="date-range">
                <DatePicker
                  placeholder="Start Date"
                  onChange={onStartDateChange}
                  value={start ? moment(start, "YYYY-MM-DD") : null}
                  format="YYYY-MM-DD"
                />
                <DatePicker
                  placeholder="End Date"
                  onChange={onEndDateChange}
                  value={end ? moment(end, "YYYY-MM-DD") : null}
                  format="YYYY-MM-DD"
                />
                <Button
                  type="primary"
                  icon={<SearchOutlined />}
                  loading={loading}
                  onClick={onSearchBtnClick}
                  shape="circle"
                  disabled={value?.length === 0 ? true : false}
                  style={{
                    minWidth: "auto",
                    width: "146px",
                    height: "37px",
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="graph-filter">
          <div className="scale">
            {channelss?.length > 0 &&
              channelss?.map((itm) => (
                <div className="scale-flex">
                  <div
                    className="suana"
                    style={{ border: "3px solid " + itm?.color }}
                  />
                  <p>{itm?.value?.name}</p>
                </div>
              ))}
          </div>
          <div className="filter-period">
            <Button
              onClick={() => {
                // setBtnSelect("hourly");
                onRangeChange("5m");
              }}
              disabled={loading}
              className={btnSelect === "5m" && "black-button"}
            >
              5 Minutes
            </Button>
            <Button
              onClick={() => {
                // setBtnSelect("hourly");
                onRangeChange("15m");
              }}
              disabled={loading}
              className={btnSelect === "15m" && "black-button"}
            >
              15 Minutes
            </Button>
            <Button
              onClick={() => {
                // setBtnSelect("hourly");
                onRangeChange("hourly");
              }}
              disabled={loading}
              className={btnSelect === "hourly" && "black-button"}
            >
              Hourly
            </Button>
            <Button
              onClick={() => {
                // setBtnSelect("daily");
                onRangeChange("daily");
              }}
              disabled={loading}
              className={btnSelect === "daily" && "black-button"}
            >
              Daily
            </Button>
            <Button
              onClick={() => {
                // setBtnSelect("weekly");
                onRangeChange("weekly");
              }}
              disabled={loading}
              className={btnSelect === "weekly" && "black-button"}
            >
              Weekly
            </Button>
            <Button
              onClick={() => {
                // setBtnSelect("monthly");
                onRangeChange("monthly");
              }}
              disabled={loading}
              className={btnSelect === "monthly" && "black-button"}
            >
              Monthly
            </Button>
            <Button
              onClick={() => {
                // setBtnSelect("yearly");
                onRangeChange("yearly");
              }}
              disabled={loading}
              className={btnSelect === "yearly" && "black-button"}
            >
              Yearly
            </Button>
          </div>
        </div>
        <div className="compare-chart">
          {channelss?.length > 0 ? (
            <div className="comp_chart" style={{position : "relative"}}>
              <CompareChart channel={channelss} time={btnSelect} />
            </div>
          ) : !loading ? (
            <div style={{ height: "400px" }}>
              <Empty />
            </div>
          ) : (
            <div className="loading" style={{ height: "400px" }}>
              <Loader />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};
export default Compare;
