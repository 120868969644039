import { notification } from "antd";
import { privateAPI, attachToken } from "../../API";
import { GET_ANALYSIS_DATA } from "../types/generalTypes";

export const getActualCo2Emission = async (payload) => {
  //   return async (dispatch) => {
  try {
    // console.log("getActualCo2Emission");
    attachToken();
    const res = await privateAPI.post(
      `/c02Emission/getActualCo2Emission`,
      payload
    );
    // console.log("getActualCo2Emission ", res);
    if (res) {
      return res?.data;
    }
    //   }
  } catch (err) {
    console.log("err", err);
    // swal("", err?.response?.data?.message || "Server Error", "error");
  }
  //   };
};

export const getCountryCo2Emission = async (payload) => {
  try {
    attachToken();
    const res = await privateAPI.post(
      `/c02Emission/getCountryCo2Emission`,
      payload
    );
    // console.log("getCountryCo2Emission ", res?.data);
    if (res) {
      return res?.data;
    }
  } catch (err) {
    console.log("err", err);
  }
};

export const getAverageConsumption = async (payload) => {
  try {
    attachToken();
    const res = await privateAPI.post(
      `/c02Emission/getAverageConsumption`,
      payload
    );
    // console.log("getCountryCo2Emission ", res?.data);
    if (res) {
      return res?.data;
    }
  } catch (err) {
    console.log("err", err);
  }
};

export const getCo2Saved = async (payload) => {
  try {
    attachToken();
    const res = await privateAPI.post(`/c02Emission/getCo2Saved`, payload);
    if (res) {
      return res?.data;
    }
  } catch (err) {
    console.log("err", err);
  }
};
