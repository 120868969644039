import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Line } from "@ant-design/plots";
import moment from "moment";
// import { getDigiChartData } from "../../redux";
// import { useDispatch } from "react-redux";

const DigiChart = ({chartData}) => {
  // const dispatch = useDispatch();
  // const [chartData, setData] = useState([]);
  // useEffect(() => {
  //   getData();
  // }, []);
  // const getData = async () => {
  //   let res = await getDigiChartData({ code: "5179" });
  //   // console.log(res);
  //   setData(res);
  // };
  console.log("data ", chartData);
  const config = {
    data: chartData,
    smooth: true,
    height: 400,
    xField: "time",
    yField: "_value",
    seriesField: "date",
    legend: false,
    color: (data) => {
      // console.log("data ",data)
      return data.date === "Average" ? "blue" : "grey";
    },
    lineStyle: (data) => {
      return data.date === "Average" ? { lineWidth: 3 } : { lineWidth: 0.5 };
    },
    yAxis: {
      label: {
        formatter: (v) =>
          `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },

    
    tooltip: {
      title: (datum, data) => {
        // console.log("datum 2", data);
        return datum;
      },
      formatter: (datum) => {
        return { name: datum.date, value: datum._value + " kW" };
      }, 
    },

    interactions: [
      {
        type: "element-highlight",
      },
    ],
  };
  return (
    <Line {...config} autoFit animation={false} style={{ marginTop: "10px" }} />
  );
};
export default DigiChart;
