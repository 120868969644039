import { Typography, DatePicker, Button, Row, Col, Switch } from "antd";
import { AiOutlineRight } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import LiveElectricConsumption from "../charts/LiveElectricConsumption";
import { getElectricConsumptionSubChannels } from "../../redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setDisplay } from "../../redux";
import Loader from "../Loader";

const LiveElectricConsumptionModal = ({ name, code, trigger }) => {
  const [consmpData, setConsmpData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [interval, setInterval] = useState(null);
  const [remColors, setRemColors] = useState([]);
  const [colors, setColors] = useState([]);
  const [chartColors, setChartColors] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const onStartChange = async (date, dateString) => {
    setLoading(true);
    setStart(dateString);
    setChartData([]);
    setTempData([]);
    setChartColors([
      "#6B4BBD",
      "#A30CB6",
      "#E14A55",
      "#8DDBCD",
      "#C4912F",
      "#26AEE2",
      "#6F0C1F",
      "#A0296F",
      "#05688E",
      "#F27920",
      "#373161",
      "#263A17",
      "#338EA9",
      "#D9664B",
      "#39664B",
    ]);
    setRemColors([]);
    const res = await getElectricConsumptionSubChannels({
      code: code,
      start: dateString,
      end: end,
    });
    setInterval(res?.int);

    setConsmpData(res?.finalresult);
    res?.finalresult?.length > 0 &&
      res?.finalresult?.map((item) => {
        item?.data?.length > 0 &&
          item?.data?.map((v) => {
            setChartData((dt) => [
              ...dt,
              {
                name: item?.name,
                date:
                  res?.int == "1h"
                    ? moment(v?.date).utc().format("YYYY-MM-DD (HH:00)")
                    : res?.int == "1d" || res?.int == "7d"
                    ? moment(v?.date).utc().format("YYYY-MM-DD")
                    : moment(v?.date).utc().format("MMM-YYYY"),
                // date: moment(v?.date).format("MMM"),
                // interval: v?.interval,
                value: v?.value,
              },
            ]);
          });
      });
    setLoading(false);
  };
  const onEndChange = async (date, dateString) => {
    setLoading(true);
    setEnd(dateString);
    setChartData([]);
    setTempData([]);
    setChartColors([
      "#6B4BBD",
      "#A30CB6",
      "#E14A55",
      "#8DDBCD",
      "#C4912F",
      "#26AEE2",
      "#6F0C1F",
      "#A0296F",
      "#05688E",
      "#F27920",
      "#373161",
      "#263A17",
      "#338EA9",
      "#D9664B",
      "#39664B",
    ]);
    setRemColors([]);
    const res = await getElectricConsumptionSubChannels({
      code: code,
      start: start,
      end: dateString,
    });
    setInterval(res?.int);
    setConsmpData(res?.finalresult);
    res?.finalresult?.length > 0 &&
      res?.finalresult?.map((item) => {
        item?.data?.length > 0 &&
          item?.data?.map((v) => {
            setChartData((dt) => [
              ...dt,
              {
                name: item?.name,
                date:
                  res?.int == "1h"
                    ? moment(v?.date).utc().format("YYYY-MM-DD (HH:00)")
                    : res?.int == "1d" || res?.int == "7d"
                    ? moment(v?.date).utc().format("YYYY-MM-DD")
                    : moment(v?.date).utc().format("MMM-YYYY"),
                // interval: v?.interval,
                value: v?.value,
              },
            ]);
          });
      });
    setLoading(false);
  };
  const onSwitch = (name, color, event) => {
    if (event) {
      let clr = remColors.find((obj) => {
        return obj.index === color;
      });
      let notclr = remColors.filter((obj) => {
        return obj.index !== color;
      });
      console.log("index ", color);
      console.log("clr?.color ", clr?.color);
      chartColors.splice(color, 0, clr?.color);
      setChartColors([...chartColors]);
      // console.log("chartColors.splice ", chartColors);
      setRemColors(notclr);
      let newData = tempData?.filter((obj) => {
        return obj.name === name;
      });
      let newDataTwo = tempData?.filter((obj) => {
        return obj.name !== name;
      });
      setTempData(newDataTwo);
      newData?.length &&
        newData?.map((dta) => {
          setChartData((tmp) => [...tmp, dta]);
        });
    } else {
      let ccc = colors[color];
      let newclr = chartColors?.filter((obj) => {
        return obj !== ccc;
      });
      setChartColors(newclr);
      setRemColors((remColors) => [
        ...remColors,
        { index: color, color: colors[color] },
      ]);
      let newData = chartData?.filter((obj) => {
        return obj.name === name;
      });
      let newDataTwo = chartData?.filter((obj) => {
        return obj.name !== name;
      });
      newData?.length &&
        newData?.map((dta) => {
          setTempData((tmp) => [...tmp, dta]);
        });
      setChartData(newDataTwo);
    }
  };

  const getData = async () => {
    setLoading(true);
    const res = await getElectricConsumptionSubChannels({
      code: code,
    });
    // console.log("REs ", res);
    setConsmpData(res?.finalresult);
    setInterval(res?.int);
    setChartData([]);
    res?.finalresult?.length > 0 &&
      res?.finalresult?.map((item) => {
        item?.data?.length > 0 &&
          item?.data?.map((v) => {
            setChartData((dt) => [
              ...dt,
              {
                name: item?.name,
                date:
                  res?.int == "1h"
                    ? moment(v?.date).utc().format("YYYY-MM-DD (HH:00)")
                    : res?.int == "1d" || res?.int == "7d"
                    ? moment(v?.date).utc().format("YYYY-MM-DD")
                    : moment(v?.date).utc().format("MMM-YYYY"),
                // moment(v?.date).utc().format("MMM-YYYY"),
                value: v?.value,
              },
            ]);
          });
      });
    setLoading(false);
  };

  const detailHandler = async () => {
    let tempArr = [];
    consmpData?.map((itm) => {
      tempArr.push({
        code: itm?.code,
        name: itm?.name,
      });
    });
    const res = await dispatch(setDisplay());
    history.push("/details", { seeInDetail: tempArr, start, end });
  };
  useEffect(() => {
    // console.log("chartData ", chartData);
    chartData.sort((a, b) => a.name.localeCompare(b.name));
    // console.log("sortedChart ", chartData);
  }, [chartData]);
  useEffect(() => {
    getData();
    setColors([
      "#6B4BBD",
      "#A30CB6",
      "#E14A55",
      "#8DDBCD",
      "#C4912F",
      "#26AEE2",
      // "#6F0C1F",
      // "#A0296F",
      // "#05688E",
      // "#F27920",
      // "#373161",
      // "#263A17",
      // "#338EA9",
      // "#D9664B",
      // "#39664B",
    ]);
    setChartColors([
      "#6B4BBD",
      "#A30CB6",
      "#E14A55",
      "#8DDBCD",
      "#C4912F",
      "#26AEE2",
      // "#6F0C1F",
      // "#A0296F",
      // "#05688E",
      // "#F27920",
      // "#373161",
      // "#263A17",
      // "#338EA9",
      // "#D9664B",
      // "#39664B",
    ]);
    setRemColors([]);
    setTempData([]);
  }, [trigger]);

  return (
    <div className="LECM-container">
      <div className="head">
        <div className="flex">
          <div className="blue" />
          <Typography.Title
            className="title"
            level={5}
            style={{ fontSize: "18px", fontWeight: 600 }}
          >
            {name} Live Electric Consumption
          </Typography.Title>
        </div>
        <div className="date-range">
          <DatePicker placeholder="Start Date" onChange={onStartChange} />
          <DatePicker placeholder="End Date" onChange={onEndChange} />
        </div>
      </div>
      <div className="btn">
        <Button onClick={detailHandler}>
          See in Details
          <AiOutlineRight />
        </Button>
      </div>
      <Row gutter={[30, 30]} align={"middle"}>
        <Col md={18}>
          <div className="modal-graph">
            {chartData ? (
              !loading ? (
                <LiveElectricConsumption
                  data={chartData}
                  colors={chartColors}
                />
              ) : (
                <div
                  style={{
                    height: "400px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Loader />
                </div>
              )
            ) : null}
          </div>
        </Col>
        <Col md={6}>
          <div className="option-list">
            {consmpData?.length > 0 &&
              consmpData?.map((itm, i) => (
                <div className="option">
                  <div className="flex-div">
                    <div
                      style={{
                        background: colors[i],
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                      }}
                    />
                    <p>{itm?.name}</p>
                  </div>
                  <div className="switches">
                    <Switch
                      checkedChildren="ON"
                      // loading ={chartData?.findIndex(obj => {return obj.name === itm.name}) !== -1 ? false : true}
                      unCheckedChildren="OFF"
                      // defaultChecked
                      checked={
                        chartData?.findIndex((obj) => {
                          return obj.name === itm.name;
                        }) !== -1
                          ? true
                          : false
                      }
                      onChange={(event) => onSwitch(itm?.name, i, event)}
                    />
                  </div>
                </div>
              ))}
            {/* <div className="option">
              <div className="flex-div">
                <div className="blue" />
                <p>Suana 2</p>
              </div>
              <div className="switches">
                <Switch
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                  defaultChecked
                />
              </div>
            </div>
            <div className="option">
              <div className="flex-div">
                <div className="red" />
                <p>Suana 3</p>
              </div>
              <div className="switches">
                <Switch
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                  defaultChecked
                />
              </div>
            </div>
            <div className="option">
              <div className="flex-div">
                <div className="black" />
                <p>Pool 1</p>
              </div>
              <div className="switches">
                <Switch
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                  defaultChecked
                />
              </div>
            </div> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LiveElectricConsumptionModal;
