import Layout from "../Layout/MainLayout";
import SenkyChart from "../Components/charts/SenkyChart";
import Loader from "../Components/Loader";
import Histogram from "../Components/charts/Histogram";
import PolarChart from "../Components/charts/PolarChart";
import HeatmapPolar from "../Components/charts/HeatmapPolar";
import { Row, Typography, Col, Divider, Select, TreeSelect, Empty } from "antd";
import { useEffect, useState } from "react";
import {
  getClient,
  getDigitalization,
  getHistogramData,
  createLog,
  getClientMeasNodes,
  getDigiChartData,
} from "../redux";
import { useDispatch, useSelector } from "react-redux";
import missingTimes from "../Util/timeranges.json";
import DigiChart from "../Components/charts/digiChart";

const LoadPeak = () => {
  const colors = useSelector((state) => state.userReducer.colors);
  let dropDownColors = [
    "#FF33FF",
    "#FFFF99",
    "#00B3E6",
    "#E6B333",
    "#3366E6",
    "#999966",
    "#99FF99",
    "#80B300",
    "#E6B3B3",
    "#6680B3",
    "#66991A",
    "#FF99E6",
    "#CCFF1A",
    "#FF1A66",
    "#E6331A",
    "#33FFCC",
    "#66994D",
    "#B366CC",
    "#4D8000",
    "#B33300",
    "#CC80CC",
    "#66664D",
    "#991AFF",
    "#E666FF",
    "#4DB3FF",
    "#1AB399",
    "#E666B3",
    "#33991A",
    "#CC9999",
    "#B3B31A",
    "#00E680",
    "#4D8066",
    "#809980",
    "#E6FF80",
    "#1AFF33",
    "#999933",
    "#FF3380",
    "#CCCC00",
    "#66E64D",
    "#4D80CC",
    "#9900B3",
    "#E64D66",
    "#4DB380",
    "#FF4D4D",
    "#99E6E6",
    "#6666FF",
  ];
  const dispatch = useDispatch();

  const [data, setData] = useState(null);
  // const [user, setUser] = useState(null);
  const [histogram, setHistogram] = useState([]);
  const [secondData, setSecondData] = useState([]);
  const [heatmapPolar, setHeatmapPolar] = useState([]);
  const [currentCode, setCurrentCode] = useState(null);
  const [filter, setFilter] = useState("week");

  const [treeData, setTreeData] = useState([]);
  const [value, setValue] = useState(undefined);
  const [range, setRange] = useState("week");
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(false);

  const onChange = async (newValue) => {
    setLoading(true);
    setValue(newValue);
    console.log("newValue ", newValue);
    let code = JSON.parse(newValue.split("+")?.[0])?.code;
    console.log("code ", code);
    let res = await getDigiChartData({ code, range });
    // console.log(res);
    setChartData(res);
    setLoading(false);
  };

  const onRangeChange = async (newValue) => {
    setLoading(true);
    setRange(newValue);
    let code = JSON.parse(value.split("+")?.[0])?.code;
    console.log("code ", code);
    let res = await getDigiChartData({ code, range: newValue });
    setChartData(res);
    setLoading(false);
  };
  let tempArr = [];

  const getData = async () => {
    const res = await dispatch(getClientMeasNodes());
    // tempArr.push(
    //   {
    //     value:
    //       JSON.stringify({
    //         code: "carbonIntensity",
    //         name: "Carbon Intensity",
    //       }) +
    //       "+" +
    //       "#FF6633",
    //     title: (
    //       <div className="tree-flex">
    //         <div className="blue-block" style={{ background: "#FF6633" }} />
    //         <div className="tree-parent">
    //           <p>Carbon Intensity</p>
    //         </div>
    //       </div>
    //     ),
    //   },
    //   {
    //     value:
    //       JSON.stringify({
    //         code: "fossilFuelPercentage",
    //         name: "Fossil Fuel Percentage",
    //       }) +
    //       "+" +
    //       "#FFB399",
    //     title: (
    //       <div className="tree-flex">
    //         <div className="blue-block" style={{ background: "#FFB399" }} />
    //         <div className="tree-parent">
    //           <p>Fossil Fuel Percentage</p>
    //         </div>
    //       </div>
    //     ),
    //   }
    // );
    let count = 0;
    res?.length &&
      res?.map((nodes, i) => {
        let tempChildren = [];
        count++;
        nodes?.subNodes?.length &&
          nodes?.subNodes?.map((sub, index) => {
            count++;

            tempChildren.push({
              value:
                JSON.stringify({ code: sub?.code, name: sub?.name }) +
                "+" +
                dropDownColors[count],
              title: (
                <div
                  className="tree-flex"
                  key={
                    JSON.stringify({ code: sub?.code, name: sub?.name }) +
                    "+" +
                    dropDownColors?.[count]
                  }
                >
                  <div
                    className="blue"
                    style={{ background: dropDownColors?.[count] }}
                  />
                  <div className="tree-parent">
                    <p>{sub?.name}</p>
                  </div>
                </div>
              ),
            });
          });
        tempArr.push({
          value:
            JSON.stringify({ code: nodes?.code, name: nodes?.name }) +
            "+" +
            dropDownColors?.[count],
          title: (
            <div
              className="tree-flex"
              key={
                JSON.stringify({ code: nodes?.code, name: nodes?.name }) +
                "+" +
                dropDownColors?.[count]
              }
            >
              <div
                className="blue-block"
                style={{ background: dropDownColors?.[count] }}
              />
              <div className="tree-parent">
                <p>{nodes?.name}</p>
              </div>
            </div>
          ),
          children: tempChildren,
        });
      });
    setTreeData(tempArr);
  };

  useEffect(() => {
    (async () => {
      let digitalData = await getDigitalization();
      setData(digitalData);
      getData();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let client = await dispatch(getClient());
      let data = [];
      let clientObj = client?.measNodes?.filter((obj) => {
        return obj?.Type === "power";
      });
      console.log("clientObj ", clientObj);
      // let node = clientObj[0];
      clientObj.map(async (node) => {
        let histogramData = await getHistogramData({
          filter,
          code: node.code,
          name: node.name,
        });
        data.push(histogramData.data);
        if (data.length === clientObj.length) {
          setHistogram(data);
          getHeatmapPolar(data);
        }
      });
    })();
  }, [filter]);

  const getHeatmapPolar = async (Data) => {
    let result = [];

    Data?.map((data) => {
      let tempResult = [];

      let maximum = Math.max.apply(
        Math,
        data.values.map((o) => o.value)
      );
      maximum = (Math.round(maximum / 1000) * 1000).toFixed();

      let interval = maximum / 10;
      let i = 0;
      while (i <= maximum) {
        let filtered = data?.values?.filter((obj) => {
          return obj.value >= i && obj.value <= i + interval;
        });
        filtered?.map((data) => {
          data.series = `${i / 1000} kW - ${(i + interval) / 1000} kW`;
        });

        let missingValues = [];
        missingTimes.map((data) => {
          missingValues.push({
            time: data.time,
            value: data.value,
            series: `${i / 1000} kW - ${(i + interval) / 1000} kW`,
          });
        });
        tempResult.push(...filtered, ...missingValues);
        // setHeatmapPolar([...heatmapPolar, ]);
        i += interval;
      }
      result.push(tempResult);
    });
    setHeatmapPolar(result);
  };

  // console.log("heatmapPolar", heatmapPolar);

  return (
    <Layout active="digitalization">
      <Typography.Title level={2}>Digitalization</Typography.Title>

      {/* <DigiChart /> */}
      <div className="digiDropdown">
        <Row gutter={[10, 10]}>
          <Col xs={12} md={7}>
            <TreeSelect
              // showSearch
              // multiple
              maxTagCount={1}
              treeIcon={false}
              switcherIcon={<></>}
              style={{
                width: "100%",
              }}
              value={value}
              dropdownStyle={{
                maxHeight: 400,
                overflow: "auto",
              }}
              // allowClear
              treeDefaultExpandAll
              onChange={onChange}
              treeData={treeData}
            />
          </Col>
          <Col xs={12} md={7}>
            <Select
              defaultValue={"week"}
              onChange={
                onRangeChange
                // async (value) => {

                // }
              }
              style={{ width: 200 }}
            >
              <Select.Option value="week">Last 7 Days</Select.Option>
              <Select.Option value="month">Last 30 Days</Select.Option>
            </Select>
          </Col>
        </Row>
      </div>
      {loading ? (
        <Loader />
      ) : chartData.length > 0 ? (
        <DigiChart chartData={chartData} />
      ) : (
        <Empty />
      )}

      <div
        className="flex-between digitalization-header"
        style={{ marginBottom: "20px" }}
      >
        <Typography.Title level={3}>Senky Diagram</Typography.Title>
        <Select
          defaultValue={"week"}
          onChange={(value) => {
            createLog({
              page: "digitalization",
              section: "senky chart",
              filter: value,
              description: "get senky cahrt diagram for the timescale " + value,
            });
          }}
          style={{ width: 200 }}
        >
          <Select.Option value="week">Last Week</Select.Option>
          <Select.Option value="month">Last Month</Select.Option>
          <Select.Option value="year">Last Year</Select.Option>
        </Select>
      </div>
      <SenkyChart Data={data ? data : []} />

      <div className="flex-between digitalization-header">
        <Typography.Title level={3}>Power Histograms</Typography.Title>
        <Select
          defaultValue={"week"}
          onChange={(value) => {
            setFilter(value);
            setHistogram([]);
            createLog({
              page: "digitalization",
              section: "power histogram",
              filter: value,
              description: "get power histograms for the time period " + value,
            });
            // getHistogramByFIlter(value, node.code, node.name, ind);
          }}
          style={{ width: 200 }}
        >
          <Select.Option value="week">Last Week</Select.Option>
          <Select.Option value="month">Last Month</Select.Option>
          <Select.Option value="year">Last Year</Select.Option>
        </Select>
      </div>
      <Divider />

      <Row gutter={[40, 20]} align="bottom">
        {histogram?.length === 0 ? (
          <div className="loading">
            <Loader />
          </div>
        ) : (
          histogram?.map((node, ind) => (
            <>
              <Col xs={24} md={12} key={ind}>
                <div className="histogram">
                  <div>
                    <Typography.Title level={4}>
                      {node.name} Consumption Clustering
                    </Typography.Title>
                  </div>
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <p
                      style={{
                        writingMode: "vertical-lr",
                        position: "absolute",
                        top: "50%",
                        left: 0,
                        opacity: 0.6,
                        fontSize: "12px",
                        margin: "-50px 0 0 0",
                      }}
                    >
                      Hours
                    </p>
                    <div
                      style={{
                        marginLeft: "30px",
                      }}
                    >
                      <Histogram
                        Data={node.values}
                        color={colors[ind]}
                        code={node.code}
                        setCurrentCode={setCurrentCode}
                        setSecondData={(data) => {
                          setSecondData(data);
                        }}
                        // heatmapPolar={heatmapPolar}
                        // setHeatmapPolar={(data) => {
                        //   setHeatmapPolar(data);
                        // }}
                      />
                    </div>
                  </div>
                  <p
                    style={{
                      textAlign: "center",
                      opacity: 0.6,
                      margin: "10px 0 0 0",
                      fontSize: "12px",
                    }}
                  >
                    Values Range (kW)
                  </p>
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className="histogram">
                  <div>
                    <Typography.Title level={4}>
                      {node.name} Consumption Cluster Timely Distribution
                    </Typography.Title>
                  </div>
                  {/* <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <p
                        style={{
                          writingMode: "vertical-lr",
                          position: "absolute",
                          top: "50%",
                          left: 0,
                          opacity: 0.6,
                          fontSize: "12px",
                          margin: "-50px 0 0 0",
                        }}
                      >
                        Hours
                      </p> */}
                  <div
                    style={{
                      marginLeft: "30px",
                    }}
                  >
                    <HeatmapPolar
                      Data={heatmapPolar ? heatmapPolar[ind] : []}
                      colors={colors}
                    />
                  </div>
                  {/* </div> */}
                  <p
                    style={{
                      textAlign: "center",
                      opacity: 0.6,
                      margin: "10px 0 0 0",
                      fontSize: "12px",
                      visibility: "hidden",
                    }}
                  >
                    .
                  </p>
                </div>
              </Col>
              {secondData?.length > 0 && (
                <>
                  <Col xs={24} md={12}>
                    {currentCode === node.code && (
                      <div className="histogram">
                        <div
                          style={{
                            marginLeft: "30px",
                          }}
                        >
                          <PolarChart
                            Data={secondData ? secondData : []}
                            colors={colors}
                          />
                        </div>
                        <p
                          style={{
                            textAlign: "center",
                            opacity: 0.6,
                            margin: "10px 0 0 0",
                            fontSize: "12px",
                            visibility: "hidden",
                          }}
                        >
                          .
                        </p>
                      </div>
                    )}
                  </Col>
                  <Col xs={0} md={12} />
                </>
              )}
            </>
          ))
        )}
      </Row>
    </Layout>
  );
};

export default LoadPeak;
