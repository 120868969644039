import { notification } from "antd";
import { privateAPI, attachToken } from "../../API";
import { GET_ANALYSIS_DATA } from "../types/generalTypes";

export const getStationsData = async () => {
  try {
    attachToken();
    const res = await privateAPI.get(`/emobility/get-charging-stations`);
    console.log("station data", res);

    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log("err", err);
    // swal("", err?.response?.data?.message || "Server Error", "error");
  }
};
export const getChargingHeatmap = async () => {
  try {
    attachToken();
    const res = await privateAPI.get(`/emobility/get-heatmap-data`);

    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log("err", err);
    // swal("", err?.response?.data?.message || "Server Error", "error");
  }
};
